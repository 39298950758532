import React from "react";
import titulo from "../../../../media/product/kit_renovacion/titulo_1.webp";
import useValueByBreakpoints from "../../../helpers/hooks/UseValueByBreakpoints";
import { Box, Grid } from "@mui/material";

const Title = () => {
    const price_image_width = useValueByBreakpoints({
        xs: "100%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const img_styles = {
        width: price_image_width,
    };

    return (
        <Box style={{ display: "flex", justifyContent: "space-around" }}>
            <img src={titulo} alt="title" style={img_styles} />
        </Box>
    );
};

export default Title;
