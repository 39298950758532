import { Box, Paper } from "@mui/material";
import React from "react";
import BuyNowButton from "./BuyNowButton";
import PaymentAgainstDeliveryMessage from "./PaymentAgainstDeliveryMessage";
import WhatsappButton from "./WhatsappButton";
import background from "../../../media/resources/background_dark.webp";

const CallToAction = ({ product, width }) => {
    return (
        <Box
            position="fixed"
            sx={{
                bottom: 0,
                left: 0,
                width: "100%",
            }}
        >
            <Box
                sx={{
                    textAlign: "right",
                    width: width + "px",
                    margin: "0 auto",
                }}
            >
                <WhatsappButton product={product} />
            </Box>
            <Box
                sx={{
                    width: width + "px",
                    margin: "0 auto",
                }}
            >
                <Paper
                    elevation={8}
                    sx={{
                        px: 2,
                        py: 1,
                    }}
                >
                    {/*<PaymentAgainstDeliveryMessage />*/}
                    <BuyNowButton product={product} />
                </Paper>
            </Box>
        </Box>
    );
};

export default CallToAction;
