import React, { useEffect } from "react";
import tituloDescription from "../../../../media/product/kit_renovacion/titulo_3.webp";
import description from "../../../../media/product/kit_renovacion/titulo_4.webp";
import { Box } from "@mui/material";
import useValueByBreakpoints from "../../../helpers/hooks/UseValueByBreakpoints";

const Description = () => {
    const price_image_width = useValueByBreakpoints({
        xs: "100%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const img_styles = {
        width: price_image_width,
    };
    return (
        <Box sx={{ p: 2, textAlign: "center" }}>
            <img src={tituloDescription} style={img_styles} />
            <img src={description} style={img_styles} />
        </Box>
    );
};

export default Description;
