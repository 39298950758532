import { Typography } from "@mui/material";
import useValueByBreakpoints from "../../../helpers/hooks/UseValueByBreakpoints";

const Presentation = () => {
    const variant = useValueByBreakpoints({ xs: "h3", md: "h4" });
    return (
        <>
            <Typography variant={variant} textAlign="center">
                ¡Descubre los mejores productos para el cuidado de tu vehículo
                en{" "}
                <Typography variant="span" highlight={true}>
                    COLOMBIA WASH
                </Typography>
                !
            </Typography>
            <Typography textAlign="center"></Typography>
        </>
    );
};

export default Presentation;
