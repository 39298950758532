import React from "react";
import { Container, Typography } from "@mui/material";

const Title = () => {
   
    return (
        <Typography variant="h3">
            <Typography variant="span" highlight={true} >
            ¡Cansado{" "}
            </Typography>             
            <Typography variant="span" >
                de lavar tu carro o moto y que al{" "}
            </Typography>
            <Typography variant="span" highlight={true} >
                otro día {" "}
            </Typography>
            <Typography variant="span">
                ya este{" "}
            </Typography>
            <Typography variant="span" highlight={true} >
                sucio!
            </Typography>
        </Typography>
    );
};

export default Title;