import ContraEntrega from "../../products/components/kit_renovacion/ContraEntrega";
import Description from "../../products/components/kit_renovacion/Description";
import Details from "../../products/components/kit_renovacion/Details";
import Footer from "../../products/components/kit_renovacion/Footer";
import Presentation from "../../products/components/kit_renovacion/Presentation";
import Promotion from "../../products/components/kit_renovacion/Promotion";
import Title from "../../products/components/kit_renovacion/Title";
import media_kit_renovacion from "../../../media/product/images/kit_renovacion_x3.webp";

const kit_renovacion = {
    code: "1",
    name: "KIT DE RENOVACIÓN X3 + OBSEQUIO",
    // Ruta para almacenar métricas en la base de datos
    route_name: "kit_renovador",
    price: 69900,
    apis: {
        funnel_2: {
            //url: "http://localhost:8000/api/new-order",
            url: "https://boostec-sp-auto-zy9m3.ondigitalocean.app/api/new-order",
        },
    },
    contents: {
        title: { funnel_2: <Title /> },
        presentation: { funnel_2: <Presentation /> },
        description: { funnel_2: <Description /> },
        details: { funnel_2: <Details /> },
        promotion: { funnel_2: <Promotion /> },
        contraentrega: { funnel_2: <ContraEntrega /> },
        footer: { funnel_2: <Footer /> },
        presentations: [{ price: "$69.900", description: "SHAMPOO EN SACHET" }],
        whatsapp_message: "Hola, estoy interesado(a) en el *Kit de Renovacion*",
        route_image_xs:
            window.location.origin + "/statics/kit_renovacion_x3_xs.webp",
        default_presentation: 0,
        card_content: {
            title: "kIT DE RENOVACIÓN x3",
            description:
                "Renueva y restaura tu moto o carro con el increíble kit de renovación x3. Partes negras, farolas y rayones.",
            price: "$69.900",
            path: "/product/2/kit_renovacion",
            media: media_kit_renovacion,
        },
    },
    related: [],
    webhook:
        "https://hooks.slack.com/services/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",
    channel: "#ventas-kit-renovacion",
    whatsapp_number: "3104384438",
    pixel: {
        funnel_2: "1209747992842380",
    },
};

export default kit_renovacion;
