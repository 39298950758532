import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import logo from "../../../../media/brand.webp";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

function Header() {
    return (
        <AppBar
            position="static"
            sx={{ backgroundColor: (theme) => theme.palette.dark.main }}
        >
            <Container maxWidth="xl">
                <Toolbar
                    disableGutters
                    sx={{
                        justifyContent: "center",
                    }}
                >
                    <Box sx={{ display: "flex", maxWidth: { xs: 130 } }}>
                        <Link to="/">
                            <img src={logo} alt="Colombia wash" width="100%" />
                        </Link>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default Header;
