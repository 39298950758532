import React, { useEffect, useState } from "react";
/* eslint-disable react-hooks/exhaustive-deps */
import {
    Alert,
    AlertTitle,
    Avatar,
    Backdrop,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField,
    Typography,
} from "@mui/material";
import anime from "animejs";
import useNavigationStatics from "../../../helpers/hooks/useNavigationStatics";
import settings from "../../../config/settings";
import BackdropLoading from "../../../helpers/components/BackdropLoading";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import useValidations from "../../../helpers/hooks/useValidations";
import background_renewal from "../../../../media/resources/background_renewal.webp";

const RegistrationForm = () => {
    const { addEvent } = useNavigationStatics();
    const [name, setName] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [loading, setLoading] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const { isCellPhone, minLength } = useValidations();

    const isDisabled = () => {
        return (
            !name ||
            !minLength(name, 7) ||
            !contactNumber ||
            !isCellPhone(contactNumber)
        );
    };

    const resetForm = () => {
        setName("");
        setContactNumber("");
    };

    useEffect(() => {
        addEvent("plan_referidos", "start_page");
        anime({
            targets: "#btn_register",
            scale: [0.95, 1.05],
            direction: "alternate",
            easing: "easeOutBack",
            loop: true,
        });
    }, []);

    const sendData = (e) => {
        setLoading(true);

        if (!isDisabled()) {
            addEvent("plan_referidos", "register");

            const fields = [
                {
                    title: "Usuario",
                    value: name,
                    short: false,
                },
                {
                    title: "Teléfono",
                    value: contactNumber,
                    short: false,
                },
            ];

            fetch(settings.referidos.webhook, {
                method: "POST",
                body: JSON.stringify({
                    username: "NUEVO VENDEDOR DE REFERIDOS",
                    channel: settings.referidos.channel,
                    fields,
                }),
            }).then((data) => {
                setLoading(false);
                if (data.ok) {
                    resetForm();
                    setShowResult(true);
                }
            });
        }
    };

    const startConfirmation = (e) => {
        e.preventDefault();
        setOpenConfirmation(true);
    };

    const propsAvatar = {
        p: 0.5,
        width: "30px",
        height: "30px",
    };
    const fontSizeIcon = "1.3rem";

    return (
        <form onSubmit={startConfirmation}>
            <BackdropLoading open={loading} text="Te estamos registrando" />

            <TextField
                id="name"
                label="Nombre completo"
                placeholder="Escribe aquí tu nombre"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PersonOutlineIcon />
                        </InputAdornment>
                    ),
                }}
                margin="normal"
                helperText={
                    !minLength(name, 7) && name
                        ? "Tu nombre completo debe tener por lo menos 7 caracteres"
                        : ""
                }
                FormHelperTextProps={{ sx: { color: "error.main" } }}
                size="small"
                required
            />
            <TextField
                id="contact_number"
                label="Número de contacto"
                placeholder="Escribe tu número de celular"
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <WhatsAppIcon />
                        </InputAdornment>
                    ),
                }}
                margin="normal"
                helperText={
                    !isCellPhone(contactNumber) && contactNumber
                        ? "Número de celular incorrecto"
                        : ""
                }
                FormHelperTextProps={{ sx: { color: "error.main" } }}
                size="small"
                required
            />
            <Box mt={2}>
                <Button
                    id="btn_register"
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    disabled={isDisabled()}
                    type="submit"
                >
                    REGíSTRATE
                </Button>
            </Box>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={showResult}
                onClose={() => setShowResult(false)}
            >
                <Box textAlign="center" p={2}>
                    <Box textAlign="center">
                        <CheckCircleOutlineIcon
                            color="success"
                            sx={{ fontSize: "6rem" }}
                        />
                        <Typography variant="h6" color="success.main">
                            Registro exitoso 👏🏼
                        </Typography>
                        <Typography color="primary.main">
                            Pronto te escribiremos para entregarte información
                            de los productos que podrás vender con nosotros 🚀
                        </Typography>
                    </Box>
                    <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth
                        sx={{ mt: 2 }}
                        onClick={() => setShowResult(false)}
                    >
                        Cerrar
                    </Button>
                </Box>
            </Backdrop>

            <Dialog
                sx={{ "& .MuiDialog-paper": { width: "100%" } }}
                maxWidth="xs"
                open={openConfirmation}
            >
                <DialogTitle>Confirma tu registro</DialogTitle>
                <DialogContent dividers sx={{ p: 0 }}>
                    <List
                        sx={{
                            width: "100%",
                        }}
                        disablePadding
                        dense
                    >
                        <ListItem sx={{ py: 0.2, alignItems: "flex-start" }}>
                            <ListItemAvatar sx={{ pt: 1 }}>
                                <Avatar sx={propsAvatar}>
                                    <PersonOutlineIcon
                                        sx={{ fontSize: fontSizeIcon }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary="Nombre completo"
                                secondary={name}
                            />
                        </ListItem>
                        <ListItem sx={{ py: 0.2, alignItems: "flex-start" }}>
                            <ListItemAvatar sx={{ pt: 1 }}>
                                <Avatar sx={propsAvatar}>
                                    <WhatsAppIcon
                                        sx={{ fontSize: fontSizeIcon }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary="Número de contacto"
                                secondary={contactNumber}
                            />
                        </ListItem>
                    </List>
                    <Box
                        sx={{
                            backgroundImage: "url(" + background_renewal + ")",
                            backgroundSize: "100% auto",
                        }}
                    >
                        <Alert
                            severity="info"
                            sx={{ backgroundColor: "rgba(255,255,255,.6)" }}
                        >
                            <AlertTitle>IMPORTANTE</AlertTitle>
                            Cuando confirmes tu registro, almacenaremos tus
                            datos y te enviaremos información al{" "}
                            <Typography highlight={true} variant="span">
                                número de WhatsApp
                            </Typography>{" "}
                            que registraste.
                        </Alert>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={() => setOpenConfirmation(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            setOpenConfirmation(false);
                            sendData();
                        }}
                    >
                        Si, acepto
                    </Button>
                </DialogActions>
            </Dialog>
        </form>
    );
};

export default RegistrationForm;
