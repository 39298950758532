import React from "react";
import useValueByBreakpoints from "../../../helpers/hooks/UseValueByBreakpoints";
import asesoria from "../../../../media/product/eco_clear/asesoria.webp";
import { Box, Grid, Typography } from "@mui/material";
import WhatsappButton from "../../../helpers/components/WhatsappButton";

const Footer = ({ product }) => {
    const price_image_width = useValueByBreakpoints({
        xs: "96%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const im_styles = {
        width: price_image_width,
    };
    const copiStyle = {
        maxWidth: "92%", // Ajusta el tamaño máximo del copo a un 50% del contenedor
        height: "auto",
        zIndex: 1,
        marginTop: 5,
        color: "#333c87",
        fontWeight: 600,
        fontSize: 20,
    };
    const year = new Date().getFullYear();
    return (
        <Grid>
            <Box mt={3}>
                <img src={asesoria} alt="kit" style={im_styles} />
            </Box>
            <Box>
                <WhatsappButton product={product} />
            </Box>
            <Box style={copiStyle}>
                <Typography variant="span">
                    &copy; {year} - By {""}
                </Typography>
                <Typography variant="span">BOOSTEC</Typography>
            </Box>
        </Grid>
    );
};

export default Footer;
