import React from "react";
import useValueByBreakpoints from "../../../helpers/hooks/UseValueByBreakpoints";
import { Box } from "@mui/material";

import recomendacion from "../../../../media/product/kit_renovacion/titulo_11.webp";
import cuatro_en_uno from "../../../../media/product/ultra_renovacion/titulo_9.png";
import productos from "../../../../media/product/ultra_renovacion/producto5.png";
import lanzamiento from "../../../../media/product/ultra_renovacion/titulo_10.png";

const Promotion = () => {
    const price_image_width = useValueByBreakpoints({
        xs: "96%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const img_styles = {
        width: price_image_width,
    };
    return (
        <>
            <Box>
                <img
                    src={recomendacion}
                    alt="recomendacion"
                    style={img_styles}
                />
            </Box>
            <Box>
                <img src={cuatro_en_uno} alt="promocion" style={img_styles} />
            </Box>
            <Box>
                <img src={productos} alt="promocion" style={img_styles} />
            </Box>
            <Box>
                <img src={lanzamiento} alt="promocion" style={img_styles} />
            </Box>
        </>
    );
};

export default Promotion;
