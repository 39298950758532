import React from "react";
import {
    Box,
    Container,
    Unstable_Grid2 as Grid,
    ThemeProvider,
} from "@mui/material";
import ScrollToTop from "../../../helpers/components/ScrollToTop";
import products from "../../../config/products";
import PurchaseForm from "../../../helpers/components/PurchaseForm";
import HeaderForm from "../../../helpers/components/HeaderForm";
import useFunnelTheme from "../../../helpers/hooks/useFunnelTheme";
import Footer from "../../../products/components/kit_renovacion/Footer";
import BtnRealizar from "../../../helpers/components/BtnRealizar";
import BtnKit from "../../../helpers/components/BtnKit";
import { useState } from "react";
import MessagePurchaseCompleted from "../../../helpers/components/MessagePurchaseCompleted";
import Garantia from "../../../helpers/components/Garantia";
import Comentarios from "../../../helpers/components/Comentarios";
import Price from "../../../helpers/components/Price";

const Funnel2 = ({ product }) => {
    const { getFunnelTheme } = useFunnelTheme();
    const [showMessagePurchaseCompleted, setShowMessagePurchaseCompleted] =
        useState(false);

    return (
        <ThemeProvider theme={getFunnelTheme(product)}>
            <Grid container justifyContent="center">
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={7}
                    lg={6}
                    xl={5}
                    sx={{ textAlign: "center" }}
                >
                    <ScrollToTop />
                    <Box sx={{ textAlign: "center", p: 1 }}>
                        {
                            products[product].contents.title[
                                localStorage.getItem("fnl")
                            ]
                        }
                        {/*products[product].contents.description[localStorage.getItem("fnl")]*/}
                    </Box>
                    <Box sx={{ p: 0, textAlign: "center" }}>
                        {
                            products[product].contents.presentation[
                                localStorage.getItem("fnl")
                            ]
                        }
                    </Box>
                    <BtnKit product={product} sx={{ mt: 2 }} />
                    <Box sx={{ p: 0, textAlign: "center" }}>
                        {
                            products[product].contents.description[
                                localStorage.getItem("fnl")
                            ]
                        }
                    </Box>
                    <Box sx={{ p: 0, textAlign: "center" }}>
                        {
                            products[product].contents.details[
                                localStorage.getItem("fnl")
                            ]
                        }
                    </Box>
                    <BtnRealizar product={product} />
                    <Box sx={{ p: 0, textAlign: "center" }}>
                        {
                            products[product].contents.promotion[
                                localStorage.getItem("fnl")
                            ]
                        }
                    </Box>
                    <Price product={product}/>
                    <Box sx={{ p: 2, textAlign: "center" }}>
                        {
                            products[product].contents.contraentrega[
                                localStorage.getItem("fnl")
                            ]
                        }
                    </Box>
                    <Container>
                        <Box
                            sx={{
                                border: 3,
                                borderColor: "primary.main",
                                borderRadius: 5,
                                p: 0.5,
                                boxShadow: 8,
                            }}
                        >
                            <HeaderForm product={product} />
                            <PurchaseForm
                                product={product}
                                onPurchase={() =>
                                    setShowMessagePurchaseCompleted(true)
                                }
                            />
                            <MessagePurchaseCompleted
                                open={showMessagePurchaseCompleted}
                                onClose={() =>
                                    setShowMessagePurchaseCompleted(false)
                                }
                                product={product}
                            />
                        </Box>
                        <Garantia product={product}/>
                    </Container>
                    <Comentarios product={product}/>
                    <BtnRealizar product={product} />
                    <Footer product={product} />
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default Funnel2;
