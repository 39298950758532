import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Paper, Typography } from "@mui/material";
import RelatedProductList from "./RelatedProductList";
import products from "../../config/products";

const ContentPurchaseCompleted = ({ product }) => {
    return (
        <Box textAlign="center">
            <CheckCircleOutlineIcon color="success" sx={{ fontSize: "6rem" }} />
            <Typography variant="h2">
                <Typography variant="span">Para </Typography>
                <Typography variant="span" highlight={true}>
                    enviar rápidamente{" "}
                </Typography>
                <Typography variant="span">tu pedido, </Typography>
                <Typography variant="span" highlight={true}>
                    confirma{" "}
                </Typography>
                <Typography variant="span">
                    respondiendo el mensaje que enviaremos a tu{" "}
                </Typography>
                <Typography variant="span" highlight={true}>
                    WhatsApp.{" "}
                </Typography>
            </Typography>
            {products[product].related.length ? (
                <Paper elevation={2} sx={{ p: 2, textAlign: "left" }}>
                    <Typography>
                        Termina de{" "}
                        <Typography highlight={true} variant="span">
                            renovar tu vehículo o motocicleta
                        </Typography>{" "}
                        con estos productos.
                    </Typography>
                    <RelatedProductList product={product} />
                </Paper>
            ) : null}
        </Box>
    );
};

export default ContentPurchaseCompleted;
