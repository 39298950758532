import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import colombia from "../../../data/colombia.json";

const SelectDepartment = ({ value, onChange }) => {
    const placeholder = {
        fontSize:20, 
        fontWeight:"bold", 
        fontFamily:'sans-serif'
    }
    return (
        <React.Fragment>
            <FormControl
                fullWidth
                sx={{ mt: 2, mb: 1 }}
                style={{ paddingTop: 10 }}
            >
                <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    sx={{ mt: -1, left: -12 }}
                >
                    Departamento *
                </InputLabel>
                <Select
                    sx={{ border: 2, borderRadius: 1 }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    onChange={onChange}
                    size="small"
                    style={placeholder}
                >
                    {colombia.map((option) => (
                        <MenuItem
                            key={option.code}
                            name={option.name}
                            value={option.code}
                            style={{fontSize:'30px'}}
                        >
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </React.Fragment>
    );
};

export default SelectDepartment;
