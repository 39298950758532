import superkit from "../../../../media/product/eco_wash/images/super.webp";
import useValueByBreakpoints from "../../../helpers/hooks/UseValueByBreakpoints";

const SuperKIt = () => {
    const price_image_width = useValueByBreakpoints({
        xs: "90%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const img_styles = {
        width: price_image_width,
        marginTop: 10,
    };

    return (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
            <img src={superkit} alt="superKit" style={img_styles} />
        </div>
    );
};

export default SuperKIt;
