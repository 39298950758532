import React from "react";
import video_renovador_farolas from "../../../../../media/product/kit_renovacion/videos/farolas.mp4";
import renovador_farolas from "../../../../../media/product/kit_renovacion/renovador_farolas.webp";
import useValueByBreakpoints from "../../../../helpers/hooks/UseValueByBreakpoints";
const RenovadorFarolas = () => {
    const price_image_width = useValueByBreakpoints({
        xs: "100%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const img_styles = {
        width: price_image_width,
    };
    return (
        <>
            <img src={renovador_farolas} style={img_styles} />
            <video width="100%" autoPlay muted loop>
                <source src={video_renovador_farolas} type="video/mp4" />
            </video>
        </>
    );
};

export default RenovadorFarolas;
