/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import {
    Box,
    Divider,
    Unstable_Grid2 as Grid,
    Typography,
} from "@mui/material";
import ScrollToTop from "../../../helpers/components/ScrollToTop";
import RelatedProductList from "../../../helpers/components/RelatedProductList";
import CallToAction from "../../../helpers/components/CallToAction";
import products from "../../../config/products";
import Warranty from "../../../helpers/components/Warranty";
import LogisticsOperators from "../../../helpers/components/LogisticsOperators";
import Testimonials from "../../../helpers/components/Testimonials";

const Funnel1 = ({ product }) => {
    const containerRef = useRef(null);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        if (containerRef.current) {
            setWidth(containerRef.current.offsetWidth);
        }
    }, [containerRef.current]);

    return (
        <Grid container justifyContent="center">
            <Grid
                xs={12}
                sm={8}
                md={7}
                lg={6}
                xl={5}
                ref={containerRef}
                /*sx={{ backgroundColor: "#FDFDFD" }}*/
            >
                <ScrollToTop />
                <Box sx={{ p: 2 }}>
                    <Box>
                        {
                            products[product].contents.title[
                                localStorage.getItem("fnl")
                            ]
                        }
                    </Box>
                    <Box
                        sx={{
                            background:
                                "linear-gradient(90deg, rgba(17, 17, 17, 0), rgb(255, 255, 255) 50%, rgba(17, 17, 17, 0))",
                            flex: "none",
                            height: "1px",
                            my: 1,
                        }}
                    ></Box>
                    <Box>
                        {
                            products[product].contents.description[
                                localStorage.getItem("fnl")
                            ]
                        }
                    </Box>
                </Box>

                <Box sx={{ p: 4 }}>
                    {
                        products[product].contents.presentation[
                            localStorage.getItem("fnl")
                        ]
                    }
                </Box>
                <Box sx={{ p: 2 }}>
                    <Box>
                        {
                            products[product].contents.details[
                                localStorage.getItem("fnl")
                            ]
                        }
                    </Box>
                    {products[product].contents.testimonials &&
                    products[product].contents.testimonials.length ? (
                        <Box sx={{ mt: 4 }}>
                            <Box
                                sx={{
                                    background:
                                        "linear-gradient(90deg, rgba(17, 17, 17, 0), rgb(255, 255, 255) 50%, rgba(17, 17, 17, 0))",
                                    flex: "none",
                                    height: "1px",
                                    my: 1,
                                }}
                            ></Box>
                            <Box sx={{ mt: 2 }}>
                                <Testimonials
                                    data={
                                        products[product].contents.testimonials
                                    }
                                />
                            </Box>
                        </Box>
                    ) : (
                        <></>
                    )}
                    <Box sx={{ mt: 4 }}>
                        <Warranty />
                    </Box>
                    <Box sx={{ mt: 4 }}>
                        <LogisticsOperators />
                    </Box>
                </Box>
                <Box pb={25}>
                    {products[product].related.length ? (
                        <React.Fragment>
                            <Divider />
                            <Box p={2}>
                                <Typography>
                                    También{" "}
                                    <Typography highlight={true} variant="span">
                                        te puede interesar.
                                    </Typography>
                                </Typography>
                                <RelatedProductList product={product} />
                            </Box>
                        </React.Fragment>
                    ) : null}
                </Box>
                <CallToAction product={product} width={width} />
            </Grid>
        </Grid>
    );
};

export default Funnel1;
