import { Unstable_Grid2 as Grid } from "@mui/material";
import ProductCard from "./ProductCard";

export default function Products() {
    return (
        <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} lg={6}>
                <ProductCard product="eco_wash" />
            </Grid>
            <Grid item xs={12} lg={6}>
                <ProductCard product="kit_renovacion" />
            </Grid>
        </Grid>
    );
}
