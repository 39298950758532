import React from "react";
import useValueByBreakpoints from "../../../helpers/hooks/UseValueByBreakpoints";
import Price from "../../../helpers/components/Price";
import envio from "../../../../media/product/eco_clear/envio.webp";
import { Box } from "@mui/material";

const Contraentrega = () => {
    const price_image_width = useValueByBreakpoints({
        xs: "100%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const img_styles = {
        width: price_image_width,
    };
    return (
        <>
            <Price product="eco_clear" />
            <Box>
                <img src={envio} style={img_styles} alt="envio" />
            </Box>
        </>
    );
};

export default Contraentrega;
