import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import Description from "./sections/Description";
import RegistrationForm from "./sections/RegistrationForm";

const Referidos = () => {
    return (
        <Grid container justifyContent="center">
            <Grid
                xs={12}
                sm={8}
                md={7}
                lg={6}
                xl={5}
                /*sx={{ backgroundColor: "#FDFDFD", p: 2 }}*/
            >
                <Description />
                <Box mt={4} mb={6}>
                    <Typography variant="h5">
                        REGÍSTRATE Y{" "}
                        <Typography highlight={true} variant="span">
                            EMPECEMOS A TRABAJAR
                        </Typography>
                    </Typography>
                    <RegistrationForm />
                </Box>
            </Grid>
        </Grid>
    );
};

export default Referidos;
