const routes = {
    kit_renovador: {
        path: "kit-renovador-x3",
    },
    eco_wash: {
        path: "eco_wash",
    },
    eco_clear:{
        path: "eco_clear"
    },
    ultra_renovacion:{
        path: "ultra_renovacion"
    },
    referidos: {
        path: "plan-referidos",
    },
};

export default routes;
