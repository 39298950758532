
import { ThemeProvider } from '@emotion/react'
import products from "../../../config/products";
import React, { useEffect, useState } from 'react'
import useFunnelTheme from '../../../helpers/hooks/useFunnelTheme';
import useNavigationStatics from '../../../helpers/hooks/useNavigationStatics';
import { Box, Container, Grid } from '@mui/material';
import BtnKit from '../../../helpers/components/BtnKit';
import BtnRealizar from '../../../helpers/components/BtnRealizar';
import Price from '../../../helpers/components/Price';
import ContraEntrega from '../../../products/components/kit_renovacion/ContraEntrega';
import HeaderForm from '../../../helpers/components/HeaderForm';
import PurchaseForm from '../../../helpers/components/PurchaseForm';
import MessagePurchaseCompleted from '../../../helpers/components/MessagePurchaseCompleted';
import Garantia from '../../../helpers/components/Garantia';
import Comentarios from '../../../helpers/components/Comentarios';
import Footer from '../../../products/components/kit_renovacion/Footer';

const Funnel5 = ({ product }) => {

    const { addEvent } = useNavigationStatics();
    const { getFunnelTheme } = useFunnelTheme();
    const [showMessagePurchaseCompleted, setShowMessagePurchaseCompleted] = useState(false);


    useEffect(() => {
        addEvent(
            products[product].route_name + "_" + localStorage.getItem("fnl"),
            "start_page"
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <ThemeProvider theme={getFunnelTheme(product)}>
            <Grid container
                justifyContent="center">
                <Grid item xs={12} sm={8} md={3} sx={{ textAlign: "center" }}>
                    <Box mb={1}>
                        {
                            products[product].contents.presentation[
                            localStorage.getItem("fnl")
                            ]
                        }
                    </Box>
                    <BtnKit product={product} />
                    <Box>
                        {
                            products[product].contents.contents[
                            localStorage.getItem("fnl")
                            ]
                        }
                    </Box>
                    <BtnRealizar product={product} />
                    <Box sx={{ mt:2 }}>
                        {
                            products[product].contents.promotion[
                            localStorage.getItem("fnl")
                            ]
                        }
                    </Box>
                    <Price product={product}/>
                    <ContraEntrega/>
                    <Container>
                        <Box
                            sx={{
                                border: 3,
                                borderColor: "#FF0000",
                                borderRadius: 5,
                                p: 0.5,
                                boxShadow: 8,
                            }}
                        >
                            <HeaderForm product={product} />
                            <PurchaseForm
                                product={product}
                                onPurchase={() =>
                                    setShowMessagePurchaseCompleted(true)
                                }
                            />
                            <MessagePurchaseCompleted
                                open={showMessagePurchaseCompleted}
                                onClose={() =>
                                    setShowMessagePurchaseCompleted(false)
                                }
                                product={product}
                            />
                        </Box>
                        <Garantia product={product}/>
                    </Container>
                    <Comentarios product={product}/>
                    <BtnRealizar product={product}/>
                    <Footer product={product}/>
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}

export default Funnel5