import Header from "../../helpers/components/header/Header";
import Presentation from "./components/Presentation";
import { Box, Unstable_Grid2 as Grid, Typography } from "@mui/material";
import Products from "./components/products/Products";
import Coverage from "./components/Coverage";
import WhatsappButton from "../../helpers/components/WhatsappButton";

const Home = () => {
    return (
        <>
            <Header />
            <Grid container justifyContent="center">
                <Grid container item xs={10} md={8} xl={6}>
                    <Box my={2}>
                        <Presentation />
                    </Box>
                    <Box my={2}>
                        <Products />
                    </Box>
                    <Box sx={{ mt: { xs: 4, md: 10 } }}>
                        <Coverage />
                    </Box>
                    <Box
                        sx={{
                            width: "100%",
                            mt: 6,
                            textAlign: "center",
                        }}
                    >
                        <Typography variant="h4">
                            ¿Tienes alguna inquietud?
                        </Typography>
                        <WhatsappButton product="eco_wash" />
                    </Box>

                    <Box
                        sx={{
                            my: 2,
                            width: "100%",
                        }}
                    >
                        <Typography
                            variant="body"
                            sx={{
                                textAlign: "center",
                                display: "block",
                            }}
                        >
                            &copy; {new Date().getFullYear()} - By {""}
                            <Typography variant="span">BOOSTEC</Typography>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default Home;
