import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import colombia from "../../../data/colombia.json";

const SelectCity = ({ value, onChange, department }) => {
    const placeholder = {
        fontSize:20, 
        fontWeight:"bold", 
        fontFamily:'sans-serif'
    }
    return (
        <React.Fragment>
            <FormControl
                fullWidth
                sx={{ mt: 2, mb: 1 }}
                style={{ paddingTop: 10 }}
            >
                <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    sx={{ mt: -1, left: -12 }}
                >
                    Ciudad *
                </InputLabel>
                <Select
                    sx={{ border: 2, borderRadius: 1 }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    onChange={onChange}
                    size="small"
                    style={placeholder}
                >
                    {colombia.map((option) => {
                        if (option.code === department) {
                            return option.cities.map((city) => {
                                return (
                                    <MenuItem
                                        key={city[0]}
                                        value={city[0]}
                                        name={city[1]}
                                        style={{fontSize:'30px'}}
                                    >
                                        {city[1]}
                                    </MenuItem>
                                );
                            });
                        } else {
                            return [];
                        }
                    })}
                </Select>
            </FormControl>
        </React.Fragment>
    );
};

export default SelectCity;
