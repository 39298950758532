import React from "react";
import { Container } from "@mui/material";
import quita_rayones from "../../../../../media/product/kit_renovacion/quita_rayones.webp";
import video_quita_rayones from "../../../../../media/product/kit_renovacion/videos/rayones.mp4";
import useValueByBreakpoints from "../../../../helpers/hooks/UseValueByBreakpoints";
const QuitaRayones = () => {
    const price_image_width = useValueByBreakpoints({
        xs: "100%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const img_styles = {
        width: price_image_width,
    };
    return (
        <>
            <img src={quita_rayones} style={img_styles} />
            <video width="100%" autoPlay muted loop>
                <source src={video_quita_rayones} type="video/mp4" />
            </video>
        </>
    );
};

export default QuitaRayones;
