import { Box } from "@mui/material";
import React from "react";
import useValueByBreakpoints from "../../../helpers/hooks/UseValueByBreakpoints";

import titulo from "../../../../media/product/ultra_renovacion/titulo_1.png";
import fondo from "../../../../media/product/ultra_renovacion/fondo_1.png";
import kit from "../../../../media/product/ultra_renovacion/productos_1.png";
import renueva from "../../../../media/product/ultra_renovacion/titulo_2.png";
import beneficios from "../../../../media/product/ultra_renovacion/titulo_3.png";
import accion from "../../../../media/product/ultra_renovacion/titulo_4.png";

const Presentation = () => {
    const price_image_width = useValueByBreakpoints({
        xs: "96%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const img_styles = {
        width: price_image_width,
        marginTop: "10px",
    };
    const kitStyle = {
        maxWidth: "92%", // Ajusta el tamaño máximo del copo a un 50% del contenedor
        height: "auto",
        zIndex: 1,
        marginTop: -25,
    };
    return (
        <>
            <Box>
                <img
                    src={titulo}
                    alt="kit ultra renovacion"
                    style={img_styles}
                />
            </Box>
            <Box
                sx={{
                    backgroundImage: `url(${fondo})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    position: "relative",
                }}
            >
                <img src={kit} alt="kit" style={kitStyle} />
            </Box>
            <Box>
                <img
                    src={renueva}
                    alt="texto de renovacion"
                    style={img_styles}
                />
            </Box>
            <Box>
                <img src={beneficios} alt="beneficios" style={img_styles} />
            </Box>
            <Box>
                <img src={accion} alt="accion del kit" style={img_styles} />
            </Box>
        </>
    );
};

export default Presentation;
