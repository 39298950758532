import React, { useEffect } from "react";
import btn2 from "../../../media/resources/botonNaranja.webp";
import btn3 from "../../../media/resources/botonRojo.webp";
import { Button } from "@mui/material";
import useValueByBreakpoints from "../hooks/UseValueByBreakpoints";
import anime from "animejs";

const BtnRealizar = ({ product }) => {
    useEffect(() => {
        anime({
            targets: "#buy_now_button",
            scale: [0.75, 0.95],
            direction: "alternate",
            easing: "easeOutBack",
            loop: true,
        });
    }, []);

    const price_image_width = useValueByBreakpoints({
        xs: "90%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const img_styles = {
        width: price_image_width,
    };
    const handleClick = () => {
        const element = document.getElementById("super_promo");
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: "smooth" });
        }
    };
    const getButton = () => {
        switch (product) {
            case "eco_wash":
                return btn2;
            case "kit_renovacion":
                return btn3;
            case "eco_clear":
                return btn3;
            case "ultra_renovacion":
                return btn3;
            default:
                return btn2;
        }
    };
    return (
        <Button id="buy_now_button" onClick={handleClick}>
            <img src={getButton()} alt="boton" style={img_styles} />
        </Button>
    );
};

export default BtnRealizar;
