import { Unstable_Grid2 as Grid, Typography } from "@mui/material";
import mapa from "../../../../media/resources/mapa_colombia.webp";
import aliados from "../../../../media/product/kit_renovacion/aliados.webp";
import useValueByBreakpoints from "../../../helpers/hooks/UseValueByBreakpoints";

export default function Coverage() {
    const image_width = useValueByBreakpoints({
        xs: "100%",
        sm: "90%",
        md: "80%",
    });
    const font_variant = useValueByBreakpoints({
        xs: "h4",
        sm: "h3",
    });
    return (
        <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} md={6}>
                <Typography variant={font_variant}>
                    Realizamos envíos{" "}
                    <Typography variant="span" highlight={true}>
                        gratis{" "}
                    </Typography>
                    a todo el territorio{" "}
                    <Typography variant="span" highlight={true}>
                        colombiano
                    </Typography>
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <img
                    src={mapa}
                    alt="Cobertura nacional"
                    width="100%"
                    style={{ borderRadius: "1rem" }}
                />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center", pt: 8 }}>
                <img src={aliados} alt="Aliados" width={image_width} />
            </Grid>
        </Grid>
    );
}
