const titles = {
    fontFamily: [
        "Staatliches",
        '"Segoe UI"',
        "Arial",
        "sans-serif",
        "rakesly-rg",
    ].join(","),
};
const text = {
    fontFamily: ["Syne", '"Segoe UI"', "Arial", "sans-serif"].join(","),
};
const titlesEco = {
    fontFamily: ["rakesly-rg", "Arial", "sans-serif"].join(","),
};
const textEco = {
    fontFamily: ["rakesly-lt"],
};
const argumentBold = {
    fontFamily: ["HomepageBaukasten-Bold"],
};
const argument = {
    fontFamily: ["HomepageBaukasten-Book"],
};

export const getPalette = (name) => {
    switch (name) {
        case "orange_light":
            return {
                type: "light",
                mode: "light",
                primary: {
                    main: "#f26806",
                    //242,104,6
                },
                secondary: {
                    main: "#fbc21e",
                },
                dark: {
                    main: "#2f2f2f",
                },
            };

        case "orange_dark":
            return {
                type: "dark",
                mode: "dark",
                primary: {
                    main: "#f26806",
                    //242,104,6
                },
                secondary: {
                    main: "#fbc21e",
                },
                /*background: {
                    default: "#2f2f2f",
                },*/
            };

        case "green_light":
            return {
                type: "light",
                mode: "light",
                primary: {
                    main: "#669933",
                    main1: "#f26806",
                },
                secondary: {
                    main: "#fff",
                },
                secondary2: {
                    main: "#f26806",
                },
            };
        case "kit_renovacion":
            return {
                type: "light",
                mode: "light",
                primary: {
                    main: "#FF0000",
                    main1: "#FF0000",
                },
                secondary: {
                    main: "#fff",
                },
                secondary2: {
                    main: "#f26806",
                },
            };
        case "eco_clear":
            return {
                type: "light",
                mode: "light",
                primary: {
                    main: "#00B0FA",
                    main1: "#00B0FA",
                },
                secondary: {
                    main: "#00B0FA",
                },
                secondary2: {
                    main: "#00B0FA",
                },
            }

        default:
            return { type: "light", mode: "light" };
    }
};

const getTheme = (name) => {
    switch (name) {
        case "orange_dark":
            return {
                palette: getPalette(name),
                typography: {
                    ...titles,
                    body1: text,
                    body2: text,
                    fontSize: 20,
                    h1: {
                        fontWeight: 600,
                    },
                    h2: {
                        fontWeight: 600,
                    },
                    h3: {
                        fontWeight: 600,
                    },
                    h4: {
                        fontWeight: 600,
                    },
                    h5: {
                        fontWeight: 600,
                    },
                    h6: {
                        fontWeight: 600,
                    },
                },
                components: {
                    MuiTypography: {
                        variants: [
                            {
                                props: { highlight: true },
                                style: {
                                    color: getPalette(name).primary.main,
                                    background:
                                        "linear-gradient(45deg, " +
                                        getPalette(name).secondary.main +
                                        " 0%, " +
                                        getPalette(name).primary.main +
                                        " 50%)",
                                    backgroundClip: "text",
                                    textFillColor: "transparent",
                                },
                            },
                        ],
                    },
                    MuiPaper: {
                        variants: [
                            {
                                props: { gradient: true },
                                style: ({ theme }) => ({
                                    background:
                                        theme.palette.type === "light"
                                            ? "linear-gradient(to bottom right, #FFFFFF, #ECEFF1)"
                                            : "linear-gradient(to bottom right, #2a2a2a, #121212)",
                                }),
                            },
                            {
                                props: { gradientInvert: true },
                                style: ({ theme }) => ({
                                    background:
                                        theme.palette.type !== "light"
                                            ? "linear-gradient(to bottom right, #FFFFFF, #ECEFF1)"
                                            : "linear-gradient(to bottom right, #2a2a2a, #121212)",
                                }),
                            },
                        ],
                    },
                    MuiCard: {
                        variants: [
                            {
                                props: { gradient: true },
                                style: ({ theme }) => ({
                                    background:
                                        theme.palette.type === "light"
                                            ? "linear-gradient(to bottom right, #FFFFFF, #ECEFF1)"
                                            : "linear-gradient(to bottom right, #2a2a2a, #121212)",
                                }),
                            },
                            {
                                props: { gradientInvert: true },
                                style: ({ theme }) => ({
                                    background:
                                        theme.palette.type !== "light"
                                            ? "linear-gradient(to bottom right, #FFFFFF, #ECEFF1)"
                                            : "linear-gradient(to bottom right, #2a2a2a, #121212)",
                                }),
                            },
                        ],
                    },
                    MuiBackdrop: {
                        styleOverrides: {
                            root: {
                                backgroundColor: "rgba(255,255,255,0.7)",
                                backdropFilter: "blur(.5rem)",
                            },
                            invisible: {
                                backgroundColor: "transparent",
                                backdropFilter: "none",
                            },
                        },
                    },
                    MuiDrawer: {
                        styleOverrides: {
                            paper: ({ ownerState, theme }) => ({
                                ...(ownerState.anchor === "bottom" && {
                                    borderTopRightRadius: "4rem",
                                    overflowX: "hidden",
                                }),
                            }),
                        },
                    },
                },
            };
        case "orange_light":
            return {
                palette: getPalette(name),
                typography: {
                    ...text,
                    fontSize: 20,
                    h1: {
                        ...titles,
                        fontWeight: 600,
                    },
                    h2: {
                        ...titles,
                        fontWeight: 600,
                    },
                    h3: {
                        ...titles,
                        fontWeight: 600,
                    },
                    h4: {
                        ...titles,
                        fontWeight: 600,
                    },
                    h5: {
                        ...titles,
                        fontWeight: 600,
                    },
                    h6: {
                        ...titles,
                        fontWeight: 600,
                    },
                    caption: {
                        ...titles,
                    },
                    button: {
                        ...titles,
                    },
                },
                components: {
                    MuiTypography: {
                        variants: [
                            {
                                props: { highlight: true },
                                style: {
                                    color: getPalette(name).primary.main,
                                    background:
                                        "linear-gradient(45deg, " +
                                        getPalette(name).secondary.main +
                                        " 0%, " +
                                        getPalette(name).primary.main +
                                        " 50%)",
                                    backgroundClip: "text",
                                    textFillColor: "transparent",
                                },
                            },
                        ],
                    },
                    MuiPaper: {
                        variants: [
                            {
                                props: { gradient: true },
                                style: ({ theme }) => ({
                                    background:
                                        theme.palette.type === "light"
                                            ? "linear-gradient(to bottom right, #FFFFFF, #ECEFF1)"
                                            : "linear-gradient(to bottom right, #2a2a2a, #121212)",
                                }),
                            },
                            {
                                props: { gradientInvert: true },
                                style: ({ theme }) => ({
                                    background:
                                        theme.palette.type !== "light"
                                            ? "linear-gradient(to bottom right, #FFFFFF, #ECEFF1)"
                                            : "linear-gradient(to bottom right, #2a2a2a, #121212)",
                                }),
                            },
                        ],
                    },
                    MuiCard: {
                        variants: [
                            {
                                props: { gradient: true },
                                style: ({ theme }) => ({
                                    background:
                                        theme.palette.type === "light"
                                            ? "linear-gradient(to bottom right, #FFFFFF, #ECEFF1)"
                                            : "linear-gradient(to bottom right, #2a2a2a, #121212)",
                                }),
                            },
                            {
                                props: { gradientInvert: true },
                                style: ({ theme }) => ({
                                    background:
                                        theme.palette.type !== "light"
                                            ? "linear-gradient(to bottom right, #FFFFFF, #ECEFF1)"
                                            : "linear-gradient(to bottom right, #2a2a2a, #121212)",
                                }),
                            },
                        ],
                    },
                    MuiBackdrop: {
                        styleOverrides: {
                            root: {
                                backgroundColor: "rgba(255,255,255,0.7)",
                                backdropFilter: "blur(.5rem)",
                            },
                            invisible: {
                                backgroundColor: "transparent",
                                backdropFilter: "none",
                            },
                        },
                    },
                    MuiDrawer: {
                        styleOverrides: {
                            paper: ({ ownerState, theme }) => ({
                                ...(ownerState.anchor === "bottom" && {
                                    borderTopRightRadius: "4rem",
                                    overflowX: "hidden",
                                }),
                            }),
                        },
                    },
                    MuiChip: {
                        styleOverrides: {
                            root: {
                                ...titles,
                            },
                        },
                    },
                },
            };
        case "green_light":
            return {
                palette: getPalette(name),
                typography: {
                    ...titlesEco,
                    body1: textEco,
                    body2: argument,
                    body3: argumentBold,
                    h1: {
                        fontWeight: 600,
                    },
                    h2: {
                        fontWeight: 200,
                    },
                    h3: {
                        fontWeight: 600,
                    },
                    h4: {
                        fontWeight: 200,
                    },
                    h5: {
                        fontWeight: 600,
                    },
                    h6: {
                        fontWeight: 600,
                    },
                },
                components: {
                    MuiTypography: {
                        variants: [
                            {
                                props: { highlight: true },
                                style: {
                                    color: getPalette(name).primary.main,
                                    background:
                                        getPalette(name).primary.main + "100%)",
                                    backgroundClip: "text",
                                },
                            },
                            {
                                props: { highlight: false },
                                style: {
                                    color: getPalette(name).primary.main1,
                                    background:
                                        getPalette(name).primary.main1 +
                                        "100%)",
                                    backgroundClip: "text",
                                },
                            },
                        ],
                    },
                    MuiInputLabel: {
                        styleOverrides: {
                            root: {
                                fontSize: 25,
                                fontFamily: "Arial",
                                fontWeight: "bold"
                            },
                        },
                    },
                },
            };
        case "kit_renovacion":
            return {
                palette: getPalette(name),
                typography: {
                    ...titlesEco,
                    body1: textEco,
                    body2: argument,
                    body3: argumentBold,
                    h1: {
                        fontWeight: 600,
                    },
                    h2: {
                        fontWeight: 200,
                    },
                    h3: {
                        fontWeight: 600,
                    },
                    h4: {
                        fontWeight: 200,
                    },
                    h5: {
                        fontWeight: 600,
                    },
                    h6: {
                        fontWeight: 600,
                    },
                },
                components: {
                    MuiTypography: {
                        variants: [
                            {
                                props: { highlight: true },
                                style: {
                                    color: getPalette(name).primary.main,
                                    background:
                                        getPalette(name).primary.main + "100%)",
                                    backgroundClip: "text",
                                },
                            },
                            {
                                props: { highlight: false },
                                style: {
                                    color: getPalette(name).primary.main1,
                                    background:
                                        getPalette(name).primary.main1 +
                                        "100%)",
                                    backgroundClip: "text",
                                },
                            },
                        ],
                    },
                    MuiInputLabel: {
                        styleOverrides: {
                            root: {
                                fontSize: 25,
                                fontFamily: "Arial",
                                fontWeight: "bold"
                            },
                        },
                    },
                },
            };
        case "eco_clear" :
            return {
                palette: getPalette(name),
                typography: {
                    ...titlesEco,
                    body1: textEco,
                    body2: argument,
                    body3: argumentBold,
                    h1: {
                        fontWeight: 600,
                    },
                    h2: {
                        fontWeight: 200,
                    },
                    h3: {
                        fontWeight: 600,
                    },
                    h4: {
                        fontWeight: 200,
                    },
                    h5: {
                        fontWeight: 600,
                    },
                    h6: {
                        fontWeight: 600,
                    },
                },
                components: {
                    MuiTypography: {
                        variants: [
                            {
                                props: { highlight: true },
                                style: {
                                    color: getPalette(name).primary.main,
                                    background:
                                        getPalette(name).primary.main + "100%)",
                                    backgroundClip: "text",
                                },
                            },
                            {
                                props: { highlight: false },
                                style: {
                                    color: getPalette(name).primary.main1,
                                    background:
                                        getPalette(name).primary.main1 +
                                        "100%)",
                                    backgroundClip: "text",
                                },
                            },
                        ],
                    },
                    MuiInputLabel: {
                        styleOverrides: {
                            root: {
                                fontSize: 25,
                                fontFamily: "Arial",
                                fontWeight: "bold"
                            },
                        },
                    },
                },
            }
        default:
            return {};
    }
};

export default getTheme;
