import React from "react";
import { Box, Container } from "@mui/material";
import PartesNegras from "./descriptions/PartesNegras";
import RenovadorFarolas from "./descriptions/RenovadorFarolas";
import QuitaRayones from "./descriptions/QuitaRayones";
import detailsTitulo from "../../../../media/product/kit_renovacion/titulo_5.webp";
import useValueByBreakpoints from "../../../helpers/hooks/UseValueByBreakpoints";
import BtnRealizar from "../../../helpers/components/BtnRealizar";

const Details = () => {
    const price_image_width = useValueByBreakpoints({
        xs: "96%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const img_styles = {
        width: price_image_width,
    };
    return (
        <Container>
            <Box>
                <img src={detailsTitulo} alt="comentarios" style={img_styles} />
            </Box>
            <Box sx={{ mt: 2 }}>
                <QuitaRayones />
            </Box>
            <Box sx={{ mt: 3 }}>
                <RenovadorFarolas />
            </Box>
            <Box sx={{ mt: 3 }}>
                <PartesNegras />
            </Box>
        </Container>
    );
};

export default Details;
