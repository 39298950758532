import { Button } from "@mui/material";
import React, { useEffect } from "react";
import anime from "animejs";
import useValueByBreakpoints from "../hooks/UseValueByBreakpoints";
import button1 from "../../../media/resources/quieroKitNaranja.webp";
import button2 from "../../../media/resources/quieroKitRojo.webp";
import button3 from "../../../media/resources/boton.webp";

const BtnKit = ({ product }) => {
    useEffect(() => {
        anime({
            targets: "#buy_now_button",
            scale: [0.75, 0.95],
            direction: "alternate",
            easing: "easeOutBack",
            loop: true,
        });
    }, []);

    const price_image_width = useValueByBreakpoints({
        xs: "90%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const img_styles = {
        width: price_image_width,
    };

    const handleClick = () => {
        const element = document.getElementById("super_promo");
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: "smooth" });
        }
    };
    const getButton = () => {
        let boton;
        switch (product) {
            case "eco_wash":
                boton = `${button1}`;
                break;
            case "kit_renovacion":
                boton = `${button2}`;
                break;
            case "ultra_renovacion":
                boton = `${button3}`;
                break;
            default:
                break;
        }
        return boton;
    };
    return (
        <Button id="buy_now_button" onClick={handleClick}>
            <img src={getButton()} alt="boton" style={img_styles} />
        </Button>
    );
};

export default BtnKit;
