import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import NotFound from "../../helpers/components/NotFound";
import Funnel1 from "./funnel_1/Funnel1";
import Funnel2 from "./funnel_2/Funnel2";
import products from "../../config/products";
import Funnel3 from "./funnel_3/funnel3";
import Funnel4 from "./funnel_4/funnel4";
import useNavigationStatics from "../../helpers/hooks/useNavigationStatics";
import Funnel5 from "./funnel_5/funnel5";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const FunnelFilter = () => {
    const { addEvent } = useNavigationStatics();
    const { funnel, product } = useParams();
    const [currentFunnel, setCurrentFunnel] = useState(null);
    const funnels = ["1", "2", "3", "4", "5"];
    let query = useQuery();

    const funnelExist = () => funnels.includes(funnel);
    const productExist = () => !!products[product];

    useEffect(() => {
        if (funnel) {
            // Se utiliza para identificar el embudo actual y poder enviar
            // eventos para métricas en base de datos y pixels de facebook
            localStorage.setItem("fnl", "funnel_" + funnel);
        }
        setCurrentFunnel(getFunnel());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [funnel, localStorage.getItem("fnl")]);

    /**
     * Envío de eventos de ingreso a la página
     */
    useEffect(() => {
        if (productExist() && funnelExist()) {
            if (window.location.hostname !== "localhost") {
                addEvent(
                    products[product].route_name +
                        "_" +
                        localStorage.getItem("fnl"),
                    "start_page"
                );

                if (query.has("fbclid")) {
                    if (
                        products[product].pixel &&
                        products[product].pixel[localStorage.getItem("fnl")]
                    ) {
                        window.fbq(
                            "init",
                            products[product].pixel[localStorage.getItem("fnl")]
                        );
                        window.fbq("track", "PageView");
                        window.fbq("track", "ViewContent", {
                            content_name: products[product].name,
                        });
                    }
                }

                if (query.has("ttclid")) {
                    if (
                        products[product].tiktok_pixel &&
                        products[product].tiktok_pixel[
                            localStorage.getItem("fnl")
                        ]
                    ) {
                        window.ttq.load(
                            products[product].tiktok_pixel[
                                localStorage.getItem("fnl")
                            ]
                        );
                        window.ttq.page();

                        window.ttq.track("ViewContent", {
                            value: products[product].price,
                            currency: "COP", // string. The 4217 currency code. Example: "USD".
                            contents: [
                                {
                                    content_id: products[product].code, // string. ID of the product. Example: "1077218".
                                    content_type: "product", // string. Either product or product_group.
                                    content_name: products[product].name, // string. The name of the page or product. Example: "shirt".
                                    price: products[product].price, // number. The price of a single item. Example: 25.
                                },
                            ],
                            description: products[product].name, // string. The description of the item or page. Example: "light weight cotton". // string. Non-hashed public IP address of the browser.
                        });
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFunnel = () => {
        switch (funnel) {
            case "1":
                return <Funnel1 product={product} />;
            case "2":
                return <Funnel2 product={product} />;
            case "3":
                return <Funnel3 product={product} />;
            case "4":
                return <Funnel4 product={product} />;
            case "5":
                return <Funnel5 product={product} />;
            default:
                return <></>;
        }
    };

    return funnelExist() && productExist() ? currentFunnel : <NotFound />;
};

export default FunnelFilter;
