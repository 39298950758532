import React, { useEffect } from "react";
import precio_antes from "../../../media/product/kit_renovacion/precio_antes.webp";
import precio_kit from "../../../media/product/kit_renovacion/precio.webp";
import precio_ultra from "../../../media/product/ultra_renovacion/titulo_12.png";
import precio_eco_clear from "../../../media/product/eco_clear/precio.webp";
import precio_eco_wash from "../../../media/product/eco_wash/images/precio2.webp";
import { Box } from "@mui/material";
import anime from "animejs";
import useValueByBreakpoints from "../hooks/UseValueByBreakpoints";

const Price = ({ product }) => {
    useEffect(() => {
        anime({
            targets: "#price",
            scale: [0.75, 0.95],
            direction: "alternate",
            easing: "easeOutBack",
            loop: true,
        });
    }, []);
    const price_image_width = useValueByBreakpoints({
        xs: "80%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const img_styles = {
        width: price_image_width,
        marginTop: -20,
    };

    const price = () => {
        switch (product) {
            case "eco_clear":
                return precio_eco_clear;
            case "ultra_renovacion":
                return precio_ultra;
            case "kit_renovacion":
                return precio_kit;
            case "eco_wash":
                return precio_eco_wash;
            default:
                return precio_eco_wash;
        }
    };
    return (
        <Box sx={{ my: 2 }}>
            <img src={precio_antes} alt="antes" width="250px" />
            <img id="price" src={price()} alt="precio" style={img_styles} />
        </Box>
    );
};

export default Price;
