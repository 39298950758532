import React from "react";
import fondo from "../../../../media/product/eco_wash/images/fondo_m_c.webp";
import kit from "../../../../media/product/eco_wash/images/kit.webp";
import { Box } from "@mui/material";

const Presentation = () => {
    const kitStyle = {
        maxWidth: "92%", // Ajusta el tamaño máximo del copo a un 50% del contenedor
        height: "auto",
        zIndex: 1,
        marginTop: 5,
    };

    return (
        <Box
            sx={{
                backgroundImage: `url(${fondo})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                position: "relative",
            }}
        >
            <img src={kit} alt="kit" style={kitStyle} />
        </Box>
    );
};

export default Presentation;
