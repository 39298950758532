import React from "react";
import { Box, Container } from "@mui/material";
import por1_3 from "../../../../media/product/kit_renovacion/titulo_9.webp";
import kit1 from "../../../../media/product/kit_renovacion/kit_1.webp";
import info from "../../../../media/product/kit_renovacion/titulo_11.webp";
import promo_super from "../../../../media/product/kit_renovacion/titulo_12.webp";
import kit2 from "../../../../media/product/kit_renovacion/kit_2.webp";
import obsequio from "../../../../media/product/kit_renovacion/titulo_13.webp";
import useValueByBreakpoints from "../../../helpers/hooks/UseValueByBreakpoints";

const Promotion = () => {
    const price_image_width = useValueByBreakpoints({
        xs: "100%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const img_styles = {
        width: price_image_width,
    };
    const img_styles_top = {
        marginTop: "-40px",
        width: price_image_width,
    };
    return (
        <Container>
            <Box>
                <img src={por1_3} alt="3por1" style={img_styles} />
                <img src={kit1} alt="kit" style={img_styles} />
                <img src={info} alt="informacion" style={img_styles} />
                <img src={promo_super} alt="super promo" style={img_styles} />
                <img src={kit2} alt="kit mas obsequio" style={img_styles_top} />
                <img src={obsequio} alt="obsequio" style={img_styles} />
            </Box>
        </Container>
    );
};

export default Promotion;
