import eco_clear from "./products/eco_clear";
import eco_wash from "./products/eco_wash";
import kit_renovacion from "./products/kit_renovacion";
import ultra_renovacion from "./products/ultra_renovacion";

const products = {
    kit_renovacion: kit_renovacion,
    eco_wash: eco_wash,
    eco_clear: eco_clear,
    ultra_renovacion: ultra_renovacion,
};

export default products;
