import React, { useEffect } from "react";
import { Box } from "@mui/material";
import anime from "animejs";

import partes_negras from "../../../../media/product/images/partes_negras.webp";
import quita_rayones from "../../../../media/product/images/quita_rayones.webp";
import kitBackground from "../../../../media/product/kit_renovacion/fondo_1.webp";
import titlePresentation from "../../../../media/product/kit_renovacion/titulo_2.webp";
import renovador_farolas from "../../../../media/product/images/renovador_farolas.webp";
import useValueByBreakpoints from "../../../helpers/hooks/UseValueByBreakpoints";

const Presentation = () => {
    // Tamaño de las imágenes de los productos de acuerdo al tamaño de la pantalla
    const image_width = useValueByBreakpoints({
        xs: "40%",
        sm: "40%",
        md: "30%",
        lg: "30%",
        xl: "30%",
    });

    useEffect(() => {
        const timeline = anime.timeline();
        timeline
            .add({
                targets: "#container_presentation",
                borderRadius: ["0rem", "1rem"],
                opacity: [0, 1],
                //backgroundColor: ["rgba(255,255,255,0)", "rgba(255,255,255,1)"],
                duration: 300,
                easing: "easeOutBack",
            })
            .add({
                targets: ".products",
                translateY: [100, 0],
                rotate: [25, 0],
                opacity: [0, 1],
                duration: 200,
                easing: "easeOutBack",
            })
            .add({
                targets: "#quita_rayones",
                translateX: "65%",
                scale: 0.85,
                easing: "easeInOutCubic",
                endDelay: -800,
            })
            .add({
                targets: "#renovador_farolas",
                translateX: "-65%",
                scale: 0.85,
                easing: "easeInOutCubic",
            });
    }, []);

    // Estilos iniciales aplicados a las imágenes de productos
    const img_styles = {
        width: image_width,
        position: "absolute",
        margin: "0 auto",
        left: 0,
        right: 0,
    };
    const presentation_styles = {
        maxWidth: "92%",
    };
    return (
        <>
            <Box
                sx={{
                    position: "relative",
                }}
            >
                <img
                    id="quita_rayones"
                    className="products"
                    src={quita_rayones}
                    alt="Quita rayones"
                    style={img_styles}
                />
                <img
                    id="renovador_farolas"
                    className="products"
                    src={renovador_farolas}
                    alt="Renovador farolas"
                    style={img_styles}
                />
                <img
                    id="partes_negras"
                    className="products"
                    src={partes_negras}
                    alt="Partes negras"
                    style={img_styles}
                />
            </Box>
            <Box
                sx={{
                    backgroundImage: `url(${kitBackground})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    position: "relative",
                    py: 2,
                }}
            >
                <img
                    src={partes_negras}
                    alt="Partes negras"
                    style={{
                        ...img_styles,
                        position: "relative",
                        opacity: 0,
                    }}
                />
            </Box>
            <Box sx={{ p: 0, textAlign: "center", mt: 2 }}>
                <img
                    src={titlePresentation}
                    style={presentation_styles}
                    alt=""
                />
            </Box>
        </>
    );
};

export default Presentation;
