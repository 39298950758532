import React from "react";
import Routes from "./routes/Routes";
import "../css/reset.css";
import {
    Box,
    createTheme,
    responsiveFontSizes,
    ThemeProvider,
} from "@mui/material";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import settings from "./config/settings";
import getTheme from "./config/theme";

function App() {
    // Initialize Firebase
    const app = initializeApp(settings.firebaseConfig);
    getAnalytics(app);
    //const analytics = getAnalytics(app);
    //console.log(analytics ? "-" : "--");

    let theme = createTheme(getTheme("orange_light"));
    theme = responsiveFontSizes(theme);
    //console.log(theme)
    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                {/* <CssBaseline />
                <AppBar position="fixed" sx={{ backgroundColor: "#292929" }}>
                    <Box sx={{ textAlign: "center", py: 1 }}>
                        <img alt="Logo" src={brand} width="120px" />
                    </Box>
                </AppBar>
                <main>
                    <Box pt={7}>
                        <Routes />
                    </Box>
                </main> */}
                <Box>
                    <Routes />
                </Box>
                <footer></footer>
            </ThemeProvider>
        </React.Fragment>
    );
}

export default App;
