import React from "react";
import kit3 from "../../../../media/product/eco_wash/images/kit3.webp";
import kitecologico from "../../../../media/product/eco_wash/images/titulo2.webp";
import obsequio from "../../../../media/product/eco_wash/images/titulo3.webp";
import useValueByBreakpoints from "../../../helpers/hooks/UseValueByBreakpoints";

import { Box } from "@mui/material";

const KitEcologico = () => {
    const price_image_width = useValueByBreakpoints({
        xs: "96%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const img_styles = {
        width: price_image_width,
        marginTop: "10px",
    };

    return (
        <Box sx={{ mt: 3 }}>
            <img src={kitecologico} alt="kit-ecologico" style={img_styles} />
            <Box sx={{ my: 2 }} id="super_promo">
                <img src={kit3} alt="kit3" style={img_styles} />
            </Box>
            <img src={obsequio} alt="obsequio" style={img_styles} />
        </Box>
    );
};

export default KitEcologico;
