import React from "react";
import useValueByBreakpoints from "../hooks/UseValueByBreakpoints";
import { Box, Grid } from "@mui/material";
import industria from "../../../media/resources/garantia_industria.webp";
import satisfaccion from "../../../media/resources/garantia_satisfaccion.webp";
import satisfaccionEcoWash from "../../../media/resources/satisfaccionEcoWash.webp";

const Garantia = ({ product }) => {
    const price_image_width = useValueByBreakpoints({
        xs: "97%",
        sm: "90%",
        md: "90%",
        lg: "90%",
        xl: "90%",
    });

    const img_styles = {
        width: price_image_width,
        marginTop: 10,
        marginBottom: 10,
    };
    const imgSatisfaccion = () => {
        switch (product) {
            case "eco_wash":
                return satisfaccionEcoWash;
            case ("kit_renovacion", "eco_clear", "ultra_renovacion"):
                return satisfaccion;
            default:
                return satisfaccion;
        }
    };
    return (
        <Grid>
            <Box>
                <img src={industria} alt="industria" style={img_styles} />
            </Box>
            <Box>
                <img
                    src={imgSatisfaccion()}
                    alt="satisfaccion"
                    style={img_styles}
                />
            </Box>
        </Grid>
    );
};

export default Garantia;
