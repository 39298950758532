import React from "react";
import include from "../../../../media/product/eco_wash/images/que_incluye.webp";

import ceramico from "../../../../media/product/eco_wash/images/nanoceramico.webp";
import cristales from "../../../../media/product/eco_wash/images/cristales y vidrios.webp";
import useValueByBreakpoints from "../../../helpers/hooks/UseValueByBreakpoints";
import { Box } from "@mui/material";

const Include = () => {
    const price_image_width = useValueByBreakpoints({
        xs: "96%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const img_styles = {
        width: price_image_width,
        marginTop: "10px",
    };

    return (
        <Box sx={{ mt: 6 }}>
            <img src={include} alt="superKit" style={img_styles} />
            <img src={ceramico} alt="superKit" style={img_styles} />
            <img src={cristales} alt="superKit" style={img_styles} />
            
        </Box>
    );
};

export default Include;
