/* eslint-disable react-hooks/exhaustive-deps */
import {
    Alert,
    AlertTitle,
    Avatar,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import BackdropLoading from "./BackdropLoading";
import products from "../../config/products";
import SelectProductPresentation from "./SelectProductPresentation";
import useNavigationStatics from "../hooks/useNavigationStatics";
import anime from "animejs";
import useValidations from "../hooks/useValidations";
import SelectDepartment from "./SelectDepartment";
import { LocationCity, Place } from "@mui/icons-material";
import SelectCity from "./SelectCity";
import { useLocation } from "react-router-dom";
import Adicion from "../../products/components/eco_wash/Adicion";
import icoProduct from "../../../media/product/eco_wash/images/ico.jpg"

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PurchaseForm = ({ product, onPurchase, onChangeData }) => {
    const { addEvent } = useNavigationStatics();
    const [productPresentation, setProductPresentation] = useState(
        products[product].contents.default_presentation
    );

    const [name, setName] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [department, setDepartment] = useState(null);
    const [departmentName, setDepartmentName] = useState("");
    const [city, setCity] = useState(null);
    const [cityName, setCityName] = useState("");
    const [address, setAddress] = useState("");
    const [neighborhood, setNeighborhood] = useState("");
    const [claimInOffice, setClaimInOffice] = useState(false);
    const [stringData, setStringData] = useState("");
    const [loading, setLoading] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const { isCellPhone, minLength } = useValidations();
    let query = useQuery();

    const isDisabled = () => {
        return (
            !name ||
            !minLength(name, 7) ||
            !contactNumber ||
            !isCellPhone(contactNumber) ||
            !department ||
            !city ||
            ((!address || !neighborhood || !minLength(neighborhood, 3)) &&
                !claimInOffice)
        );
    };

    const resetForm = () => {
        setName("");
        setContactNumber("");
        setDepartment(null);
        setDepartmentName("");
        setCity(null);
        setCityName("");
        setAddress("");
        setNeighborhood("");
        setClaimInOffice(false);
    };

    useEffect(() => {
        anime({
            targets: "#btn_buy",
            scale: [0.95, 1.05],
            direction: "alternate",
            easing: "easeOutBack",
            loop: true,
        });
    }, []);

    useEffect(() => {
        if (typeof onChangeData === "function") {
            onChangeData(stringData);
        }
    }, [stringData]);

    useEffect(() => {
        if (name || contactNumber || city || address) {
            setStringData(
                JSON.stringify({
                    name,
                    cellphone: contactNumber,
                    department,
                    city,
                    address,
                    neighborhood,
                })
            );
        } else {
            setStringData("");
        }
    }, [
        name,
        contactNumber,
        department,
        city,
        address,
        neighborhood,
        departmentName,
        cityName,
    ]);

    /**
     * Añade eventos de compra y envía datos de compra a slack y al backend si está configurado en el producto
     */
    const sendData = () => {
        setLoading(true);

        // Envío de eventos
        const sendEvents = () => {
            if (window.location.hostname !== "localhost") {
                addEvent(
                    products[product].route_name +
                    "_" +
                    localStorage.getItem("fnl"),
                    "buy"
                );

                if (query.has("fbclid")) {
                    if (
                        products[product].pixel &&
                        products[product].pixel[localStorage.getItem("fnl")]
                    ) {
                        window.fbq(
                            "init",
                            products[product].pixel[localStorage.getItem("fnl")]
                        );
                        window.fbq("track", "Purchase", {
                            currency: "COP",
                            value: products[product].price,
                            content_name: products[product].name,
                            ph: contactNumber,
                            ct: cityName,
                            country: "Colombia",
                            fn: name,
                        });
                    }
                } else if (query.has("ttclid")) {
                    if (
                        products[product].tiktok_pixel &&
                        products[product].tiktok_pixel[
                        localStorage.getItem("fnl")
                        ]
                    ) {
                        window.ttq.track("CompletePayment", {
                            value: products[product].price, // number. Value of the order or items sold. Example: 100.
                            currency: "COP", // string. The 4217 currency code. Example: "USD".
                            contents: [
                                {
                                    content_id: products[product].code, // string. ID of the product. Example: "1077218".
                                    content_type: "product", // string. Either product or product_group.
                                    content_name: products[product].name, // string. The name of the page or product. Example: "shirt".
                                    price: products[product].price, // number. The price of a single item. Example: 25.
                                },
                            ],
                            description: products[product].name, // string. The description of the item or page. Example: "light weight cotton". // string. Non-hashed public IP address of the browser.
                        });
                    }
                }
            }
        };

        if (!isDisabled()) {
            const slackFields = [
                {
                    title: "Producto",
                    value:
                        products[product].name +
                        " " +
                        products[product].contents.presentations[
                            productPresentation
                        ].description
                            .toString()
                            .toLowerCase(),
                    short: false,
                },
                {
                    title: "Precio",
                    value: products[product].contents.presentations[
                        productPresentation
                    ].price,
                    short: false,
                },
                {
                    title: "Cliente",
                    value: name,
                    short: false,
                },
                {
                    title: "Teléfono",
                    value: contactNumber,
                    short: false,
                },
                {
                    title: "Municipio",
                    value: cityName + " - " + departmentName,
                    short: false,
                },
                {
                    title: "Dirección",
                    value: claimInOffice ? "--- EN OFICINA ---" : address,
                    short: false,
                },
                {
                    title: "Barrio",
                    value: claimInOffice ? "--- EN OFICINA ---" : neighborhood,
                    short: false,
                },
            ];

            // Tiene api configurada para envío de orden
            if (
                products[product].apis &&
                products[product].apis[localStorage.getItem("fnl")] &&
                products[product].apis[localStorage.getItem("fnl")].url
            ) {
                fetch(products[product].apis[localStorage.getItem("fnl")].url, {
                    method: "POST",
                    body: JSON.stringify({
                        name,
                        cellphone: contactNumber,
                        department_code: department,
                        city_code: city,
                        city: cityName + " - " + departmentName,
                        address,
                        neighborhood,
                        in_office: claimInOffice ? "1" : "0",
                        product: products[product].codes[productPresentation],
                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                })
                    .then((data) => {
                        setLoading(false);
                        if (data.ok) {
                            // Envío de eventos
                            sendEvents();

                            resetForm();
                            if (typeof onPurchase === "function") {
                                onPurchase();
                            }
                        } else {
                            // Si hay error se notifica por slack
                            slackFields.push({
                                title: "data",
                                value: JSON.stringify(data),
                                short: false,
                            });
                            fetch(products[product].webhook, {
                                method: "POST",
                                body: JSON.stringify({
                                    username:
                                        "Error al registrar orden (cliente)",
                                    channel: products[product].channel,
                                    fields: slackFields,
                                }),
                            });
                            alert(
                                "No logramos registrar tu orden en este momento, pero no te preocupes almacenamos tus datos y pronto nos pondremos en contacto contigo."
                            );
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        // Si hay error se notifica por slack
                        slackFields.push({
                            title: "data",
                            value: error.message,
                            short: false,
                        });
                        fetch(products[product].webhook, {
                            method: "POST",
                            body: JSON.stringify({
                                username: "Error al registrar orden (cliente)",
                                channel: products[product].channel,
                                fields: slackFields,
                            }),
                        });
                        alert(
                            "No logramos registrar tu orden en este momento, pero no te preocupes almacenamos tus datos y pronto nos pondremos en contacto contigo."
                        );
                    });
            } else {
                const confirmation_message_url =
                    "https://wa.me/57" +
                    contactNumber +
                    "?text=!*" +
                    name.split(" ")[0] +
                    "*, estamos a un paso de enviar tu pedido!%0A%0A" +
                    "Tu pedido de 1 *" +
                    products[product].name +
                    " " +
                    products[product].contents.presentations[
                        productPresentation
                    ].description +
                    "* ya está siendo empacado, tu datos son los siguientes:%0A%0A" +
                    name +
                    "%0A" +
                    "*" +
                    (claimInOffice
                        ? "Reclamarás en oficina"
                        : address + " / " + neighborhood) +
                    "*%0A" +
                    cityName +
                    "%0A" +
                    departmentName +
                    "%0A" +
                    products[product].contents.presentations[
                        productPresentation
                    ].price +
                    "%0A%0A" +
                    "*¿Tus datos están correctos para generar tu GUÍA?*";

                slackFields.push({
                    title: "Url de confirmación",
                    value: confirmation_message_url
                        .replaceAll(" ", "+")
                        .replaceAll("#", "N°"),
                    short: false,
                });

                fetch(products[product].webhook, {
                    method: "POST",
                    body: JSON.stringify({
                        username: "Nuevo pedido",
                        channel: products[product].channel,
                        fields: slackFields,
                    }),
                }).then((data) => {
                    setLoading(false);
                    if (data.ok) {
                        sendEvents();
                        resetForm();
                        if (typeof onPurchase === "function") {
                            onPurchase();
                        }
                    } else {
                        // Si hay error se notifica por slack
                        slackFields.push({ data: data });
                        fetch(products[product].webhook, {
                            method: "POST",
                            body: JSON.stringify({
                                username:
                                    "Error al registrar orden (cliente slack)",
                                channel: products[product].channel,
                                fields: slackFields,
                            }),
                        });
                        alert(
                            "No logramos registrar tu orden en este momento, por favor verifica tu conexión a internet y prueba nuevamente."
                        );
                    }
                });
            }
        }
    };

    /**
     * Añade eventos de registro o confirmación y abre modal de confirmación de compra
     *
     * @param Event e
     */
    const startConfirmation = (e) => {
        e.preventDefault();

        if (window.location.hostname !== "localhost") {
            addEvent(
                products[product].route_name +
                "_" +
                localStorage.getItem("fnl"),
                "in_confirmation"
            );

            if (query.has("fbclid")) {
                if (
                    products[product].pixel &&
                    products[product].pixel[localStorage.getItem("fnl")]
                ) {
                    window.fbq(
                        "init",
                        products[product].pixel[localStorage.getItem("fnl")]
                    );
                    window.fbq("track", "CompleteRegistration", {
                        content_name: products[product].name,
                        ph: contactNumber,
                        ct: cityName + " - " + departmentName,
                        country: "Colombia",
                        fn: name,
                    });
                }
            }
        }

        setOpenConfirmation(true);
    };

    const propsAvatar = {
        p: 0.5,
        width: "30px",
        height: "30px",
    };
    const fontSizeIcon = "1.3rem";

    const placeholder = {
        fontSize: 20,
        fontWeight: "bold",
        fontFamily: "sans-serif",
    };

    //checkbox
    const handleCheckboxChange = () => {
        setProductPresentation((prevState) =>
            prevState === 1
                ? products[product].contents.default_presentation
                : 1
        );
    };

    useEffect(() => {
        anime({
            targets: "#price",
            scale: [0.75, 0.95],
            direction: "alternate",
            easing: "easeOutBack",
            loop: true,
        });
    }, []);

    const selectedPresentationIndex = productPresentation === 1 ? 1 : 0;

    return (
        <form onSubmit={startConfirmation}>
            {product == "eco_wash" ? (
                <>
                    <Adicion />
                    <Box
                        id="price"
                        style={{
                            backgroundColor: "red",
                            padding: "10px",
                            borderRadius: "5px",
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    style={{ fontSize: "10em", color: "white" }}
                                    checked={productPresentation}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label={
                                <span
                                    style={{
                                        fontSize: "1.5em",
                                        color: "white",
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                >
                                    <img
                                        src={icoProduct}
                                        alt="ico"
                                        style={{ marginRight: "5px", width: "100px", height: "100px" }}
                                    />
                                    Agregar desengrasante
                                </span>
                            }
                        />
                    </Box>
                </>
            ) : null}
            <Box style={{ padding: 6, textAlign: "left" }}>
                <BackdropLoading open={loading} text="Registrando tu orden" />

                {/*<FormControlLabel
                control={
                    <Checkbox
                        defaultChecked
                        onChange={(e) => setClaimInOffice(e.target.checked)}
                        checked={claimInOffice}
                    />
                }
                label="Reclamar en oficina de transportadora"
            />*/}
                <FormControl
                    variant="standard"
                    fullWidth
                    style={{ paddingTop: 10 }}
                >
                    <InputLabel shrink htmlFor="bootstrap-input">
                        Nombre y Apellido
                    </InputLabel>
                    <TextField
                        sx={{ border: 2, borderRadius: 1 }}
                        id="name"
                        placeholder="Escribe aquí tu nombre"
                        value={name}
                        onChange={(e) => setName(e.target.value.toUpperCase())}
                        fullWidth
                        margin="normal"
                        inputProps={{ style: placeholder }}
                        helperText={
                            !minLength(name, 7) && name
                                ? "Tu nombre completo debe tener por lo menos 7 caracteres"
                                : ""
                        }
                        FormHelperTextProps={{ sx: { color: "error.main" } }}
                        size="small"
                        required
                    />
                </FormControl>
                <FormControl
                    variant="standard"
                    fullWidth
                    style={{ paddingTop: 10 }}
                >
                    <InputLabel shrink htmlFor="bootstrap-input">
                        Celular (WhatsApp)
                    </InputLabel>
                    <TextField
                        sx={{ border: 2, borderRadius: 1 }}
                        id="contact_number"
                        placeholder="Escribe tu número de celular"
                        value={contactNumber}
                        onChange={(e) => setContactNumber(e.target.value)}
                        fullWidth
                        margin="normal"
                        inputProps={{ style: placeholder }}
                        helperText={
                            !isCellPhone(contactNumber) && contactNumber
                                ? "Número de celular incorrecto"
                                : ""
                        }
                        size="small"
                        FormHelperTextProps={{ sx: { color: "error.main" } }}
                        required
                    />
                </FormControl>

                <SelectDepartment
                    value={department}
                    onChange={(e, item) => {
                        setDepartment(e.target.value);
                        setDepartmentName(item.props.name);
                        setCity(null);
                    }}
                />
                <SelectCity
                    value={city}
                    onChange={(e, item) => {
                        setCity(e.target.value);
                        setCityName(item.props.name);
                    }}
                    department={department}
                />
                {/*<TextField
                id="city"
                label="Ciudad"
                placeholder="Escribe aquí el nombre de tu ciudad"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                fullWidth
                margin="normal"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <LocationCity />
                        </InputAdornment>
                    ),
                }}
                size="small"
                required
            />
                <FormControlLabel
                    control={
                        <Checkbox
                            defaultChecked
                            onChange={(e) => setClaimInOffice(e.target.checked)}
                            checked={claimInOffice}
                        />
                    }
                    label="Reclamar en oficina"
                    sx={{ mt: 1, mb: 2 }}
                />*/}
                {claimInOffice && (
                    <Alert severity="info" sx={{ mb: 4 }}>
                        <AlertTitle>LUGAR DE ENTREGA</AlertTitle>
                        Reclamarás tu kit directamente en una oficina de{" "}
                        <Typography highlight={true} variant="span">
                            INTERRAPIDISIMO
                        </Typography>
                        {" o "}
                        <Typography highlight={true} variant="span">
                            SERVIENTREGA.
                        </Typography>{" "}
                        Te informaremos cuando puedas pasar a reclamarlo.
                    </Alert>
                )}

                {!claimInOffice && (
                    <FormControl
                        variant="standard"
                        fullWidth
                        style={{ paddingTop: 10 }}
                    >
                        <InputLabel shrink htmlFor="bootstrap-input">
                            Dirección (lo más completa posible) *
                        </InputLabel>
                        <TextField
                            sx={{ border: 2, borderRadius: 1 }}
                            id="address"
                            placeholder="Calle, carrera, #apto, #torre, #casa"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            fullWidth
                            margin="normal"
                            inputProps={{ style: placeholder }}
                            size="small"
                            required
                        />
                    </FormControl>
                )}
                {!claimInOffice && (
                    <FormControl
                        variant="standard"
                        fullWidth
                        style={{ paddingTop: 10 }}
                    >
                        <InputLabel shrink htmlFor="bootstrap-input">
                            Barrio *
                        </InputLabel>
                        <TextField
                            sx={{ border: 2, borderRadius: 1 }}
                            id="neighborhood"
                            placeholder="Nombre del barrio"
                            value={neighborhood}
                            onChange={(e) =>
                                setNeighborhood(e.target.value.toUpperCase())
                            }
                            fullWidth
                            margin="normal"
                            inputProps={{ style: placeholder }}
                            helperText={
                                !minLength(neighborhood, 3) && neighborhood
                                    ? "El nombre del barrio debe tener por lo menos 3 caracteres"
                                    : ""
                            }
                            FormHelperTextProps={{
                                sx: { color: "error.main" },
                            }}
                            size="small"
                            required
                        />
                    </FormControl>
                )}
                <Box mt={2}></Box>
                <Box style={{ textAlign: "start" }}>
                    <Typography variant="h2" sx={{ mb: 1, mt: -1 }}>
                        TOTAL A PAGAR{" "}
                        <Typography highlight={false} variant="span">
                            {"  " +
                                products[product].contents.presentations[
                                    selectedPresentationIndex
                                ].price}
                        </Typography>
                    </Typography>
                    {/*<Box style={{ fontSize: 30, marginBottom: 7 }}>
                        Envío gratis{" "}
                        {city
                            ? "a " +
                              city.toLowerCase() +
                              "/" +
                              department.toLowerCase()
                            : ""}
                    </Box>*/}
                    <Box style={{ alignItems: "start" }}>
                        <Typography variant="h4">
                            La entrega se realizará de{" "}
                            <Typography highlight={false} variant="span">
                                2 a 5 días hábiles
                            </Typography>{" "}
                            y pagas al{" "}
                            <Typography highlight={false} variant="span">
                                recibir tu pedido.
                            </Typography>
                        </Typography>
                    </Box>
                </Box>
                <Box mt={2} p={2}>
                    <Button
                        id="btn_buy"
                        variant="contained"
                        color="secondary2"
                        fullWidth
                        size="large"
                        disabled={isDisabled()}
                        type="submit"
                        style={{
                            fontSize: 25,
                            borderRadius: 18,
                            color: "#FFFFFF",
                        }}
                    >
                        COMFIRMAR COMPRA{" "}
                    </Button>
                </Box>

                <Dialog
                    sx={{ "& .MuiDialog-paper": { width: "100%" } }}
                    maxWidth="xs"
                    open={openConfirmation}
                >
                    <DialogTitle variant="span">CONFIRMA TU ORDEN</DialogTitle>
                    <DialogContent dividers sx={{ p: 0 }}>
                        <List
                            sx={{
                                width: "100%",
                            }}
                            disablePadding
                            dense
                        >
                            <ListItem
                                sx={{ py: 0.2, alignItems: "flex-start" }}
                            >
                                <ListItemAvatar sx={{ pt: 1 }}>
                                    <Avatar sx={propsAvatar}>
                                        <PersonOutlineIcon
                                            sx={{ fontSize: fontSizeIcon }}
                                        />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Nombre completo"
                                    secondary={name}
                                />
                            </ListItem>
                            <ListItem
                                sx={{ py: 0.2, alignItems: "flex-start" }}
                            >
                                <ListItemAvatar sx={{ pt: 1 }}>
                                    <Avatar sx={propsAvatar}>
                                        <WhatsAppIcon
                                            sx={{ fontSize: fontSizeIcon }}
                                        />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Número de contacto"
                                    secondary={contactNumber}
                                />
                            </ListItem>
                            <ListItem
                                sx={{ py: 0.2, alignItems: "flex-start" }}
                            >
                                <ListItemAvatar sx={{ pt: 1 }}>
                                    <Avatar sx={propsAvatar}>
                                        <LocationCity
                                            sx={{ fontSize: fontSizeIcon }}
                                        />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Ciudad"
                                    secondary={
                                        cityName + " - " + departmentName
                                    }
                                />
                            </ListItem>
                            {!claimInOffice ? (
                                <ListItem
                                    sx={{ py: 0.2, alignItems: "flex-start" }}
                                >
                                    <ListItemAvatar sx={{ pt: 1 }}>
                                        <Avatar sx={propsAvatar}>
                                            <Place
                                                sx={{ fontSize: fontSizeIcon }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary="Dirección"
                                        secondary={
                                            address + " / " + neighborhood
                                        }
                                    />
                                </ListItem>
                            ) : (
                                <ListItem
                                    sx={{ py: 0.2, alignItems: "flex-start" }}
                                >
                                    <ListItemAvatar sx={{ pt: 1 }}>
                                        <Avatar sx={propsAvatar}>
                                            <Place
                                                sx={{ fontSize: fontSizeIcon }}
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary="Reclama en oficina"
                                        secondary="Podrás reclamar tu pedido en una oficina de INTERRAPIDISIMO o SERVIENTREGA"
                                    />
                                </ListItem>
                            )}
                            {/* <ListItem
                                sx={{ py: 0.2, alignItems: "flex-start" }}
                            >
                                <ListItemAvatar sx={{ pt: 1 }}>
                                    <Avatar sx={propsAvatar}>
                                        <ShoppingCart
                                            sx={{ fontSize: fontSizeIcon }}
                                        />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Producto"
                                    secondary={
                                        products[product].name +
                                        " " +
                                        products[product].contents
                                            .presentations[productPresentation]
                                            .description
                                    }
                                />
                            </ListItem>
                            <ListItem
                                sx={{ py: 0.2, alignItems: "flex-start" }}
                            >
                                <ListItemAvatar sx={{ pt: 1 }}>
                                    <Avatar sx={propsAvatar}>
                                        <AttachMoney
                                            sx={{ fontSize: fontSizeIcon }}
                                        />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Total a pagar"
                                    secondary={
                                        products[product].contents
                                            .presentations[productPresentation]
                                            .price
                                    }
                                />
                            </ListItem> */}
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            autoFocus
                            onClick={() => setOpenConfirmation(false)}
                        >
                            Modificar
                        </Button>
                        <Button
                            onClick={() => {
                                setOpenConfirmation(false);
                                sendData();
                            }}
                        >
                            Si, acepto
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </form>
    );
};

export default PurchaseForm;
