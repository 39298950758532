import { Container, Typography } from "@mui/material";
import React from "react";

const Arguments = () => {
    const fontBold = {
        fontFamily: "HomepageBaukasten-Bold",
        fontSize: 20,
    };
    const font = {
        fontFamily: "HomepageBaukasten-Book",
        fontSize: 20,
    };
    return (
        <Container sx={{ mt: 1 }}>
            <Typography
                sx={{
                    px: { xs: 0, sm: 4, textAlign: "justify", lineHeight: 1 },
                }}
            >
                <Typography variant="span" style={font}>
                    La mejor formula 100% ecologica que{" "}
                </Typography>
                <Typography variant="span" style={fontBold}>
                    lava, brilla, policha, protege,{" "}
                </Typography>
                <Typography variant="span" style={font}>
                    mantiene{" "}
                </Typography>
                <Typography variant="span" style={fontBold}>
                    limpio{" "}
                </Typography>
                <Typography variant="span" style={font}>
                    tu{" "}
                </Typography>
                <Typography variant="span" style={fontBold}>
                    carro o moto{" "}
                </Typography>
                <Typography variant="span" style={font}>
                    hasta por{" "}
                </Typography>
                <Typography variant="span" style={fontBold}>
                    55 días.{" "}
                </Typography>
            </Typography>
        </Container>
    );
};

export default Arguments;
