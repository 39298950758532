import React from "react";
import fondo from "../../../../media/product/eco_wash/images/fondo.webp";
import superEco from "../../../../media/product/eco_wash/images/titulo6.webp";
import { Box, Grid, Typography, colors } from "@mui/material";
import WhatsappButton from "../../../helpers/components/WhatsappButton";

const Footer = ({ product }) => {
    const kitStyle = {
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "92%", // Ajusta el tamaño máximo del copo a un 50% del contenedor
        height: "auto",
        zIndex: 1,
        marginTop: 5,
    };
    const whatsappStyle = {
        position: "absolute",
        top: "75%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "92%", // Ajusta el tamaño máximo del copo a un 50% del contenedor
        height: "auto",
        zIndex: 1,
        marginTop: 5,
    };
    const copiStyle = {
        maxWidth: "92%", // Ajusta el tamaño máximo del copo a un 50% del contenedor
        height: "auto",
        zIndex: 1,
        marginTop: 5,
        color: "#FFFFFF",
        fontWeight: 600,
        fontSize: 20,
    };
    const year = new Date().getFullYear();
    return (
        <Box
            sx={{
                backgroundImage: `url(${fondo})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                position: "relative",
                py: 2,
            }}
        >
            <Grid>
                <Box sx={{ pt: 10, px: { xs: 2, sm: 4 } }}>
                    <img src={superEco} alt="kit" width="100%" />
                </Box>
                <Box>
                    <WhatsappButton product={product} />
                </Box>
                <Box style={copiStyle}>
                    <Typography variant="span">
                        &copy; {year} - By {""}
                    </Typography>
                    <Typography variant="span">BOOSTEC</Typography>
                </Box>
            </Grid>
        </Box>
    );
};

export default Footer;
