import React from "react";
import useValueByBreakpoints from "../../../helpers/hooks/UseValueByBreakpoints";
import { Box } from "@mui/material";
import video_ecoWash from "../../../../media/product/eco_wash/videos/video_eco.mp4";
import negras from "../../../../media/product/eco_wash/images/partes negras.webp";

const Include_2 = () => {
    const price_image_width = useValueByBreakpoints({
        xs: "96%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const im_styles = {
        width: price_image_width,
    };

    return (
        <>
            <Box sx={{ mt: 2 }}>
                <img src={negras} alt="superKit" style={im_styles} />
            </Box>
            <video
                width="95%"
                autoPlay
                muted
                loop
                /*controls
            controlsList="nodownload"*/
            >
                <source
                    src={video_ecoWash}
                    //src="https://www.w3schools.com/html/mov_bbb.mp4"
                    type="video/mp4"
                />
            </video>
        </>
    );
};

export default Include_2;
