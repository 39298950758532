import { Container, Typography } from "@mui/material";
import React from "react";

const Question = () => {
    const fontBold = {
        fontFamily: "Rakesly-rg",
    };
    const strong = {
        backgroundColor: "#669933",
        color: "#ffffff",
        padding: "4px 10px",
        borderRadius: "10px",
        fontFamily: "Rakesly-rg",
    };
    return (
        <Container sx={{ px: 0, mt: 4, mb: 2 }} md={1}>
            <Typography
                variant="body1"
                sx={{ px: { xs: 0, sm: 0, fontSize: 30 } }}
                style={{ textAlign: "center" }}
            >
                <Typography variant="span">¿QUIERES TENER TU </Typography>
                <Typography variant="span" highlight={true} style={fontBold}>
                    MOTO{" "}
                </Typography>
                <Typography variant="span">O </Typography>
                <Typography highlight={true} variant="span" style={fontBold}>
                    CARRO{" "}
                </Typography>
                <strong style={strong}>COMO NUEVOS ?</strong>
            </Typography>
        </Container>
    );
};

export default Question;
