import React from "react";
import useValueByBreakpoints from "../hooks/UseValueByBreakpoints";

import comentariosClear from "../../../media/resources/comentarios/comentariosClear.webp";
import comentariosWash from "../../../media/resources/comentarios/comentariosWash.webp";
import comentariosRenovacion from "../../../media/resources/comentarios/comentariosRenovacion.webp";
import { Box } from "@mui/material";

const Comentarios = ({ product }) => {
    const price_image_width = useValueByBreakpoints({
        xs: "100%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const img_styles = {
        width: price_image_width,
    };
    const getComentarios = () => {
        switch (product) {
            case "eco_wash":
                return comentariosWash;
            case "kit_renovacion":
                return comentariosRenovacion;
            case "ultra_renovacion":
                return comentariosRenovacion;
            case "eco_clear":
                return comentariosClear;
        }
    };
    return (
        <Box>
            <img src={getComentarios()} alt="comentarios" style={img_styles} />
        </Box>
    );
};

export default Comentarios;
