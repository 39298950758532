import { getDatabase, increment, ref, update } from "firebase/database";

const useNavigationStatics = () => {
    const ls_key = "navigation_statics";

    const currentDate = () => {
        const date = new Date();
        return (
            date.getDate() +
            "_" +
            (date.getMonth() + 1) +
            "_" +
            date.getFullYear()
        );
    };

    const getData = () => JSON.parse(localStorage.getItem(ls_key) ?? "{}");

    /**
     * Consulta eventos registrados en la fecha actual para un destino determinado
     *
     * @param {string} target
     */
    const getEventsByTarget = (target, date) => {
        /*
        {
            target_1:{
                12_1_2023:['event_1', ... 'event_N']
                13_1_2023:['event_1', ... 'event_N']
            }
            .
            .
        }
        */
        const data = getData();
        let events = [];

        if (data[target]) {
            if (data[target][date]) {
                events = data[target][date];
            }
        }
        return events;
    };

    /**
     * Agrega un evento determinado al almacenamiento local
     *
     * @param {string} target
     * @param {string} event
     */
    const addEventToLocalStorage = (target, event) => {
        const data = getData();
        !data[target]
            ? (data[target] = { [currentDate()]: [event] })
            : (data[target] = {
                  [currentDate()]: [event].concat(
                      getEventsByTarget(target, currentDate())
                  ),
              });
        localStorage.setItem(ls_key, JSON.stringify(data));
    };

    const getCurrentUrlTargetEvent = (target, event) =>
        target + "/" + currentDate() + "/" + event;

    /**
     * Incrementa el valor de un evento si no se ha incrementado en la fecha actual
     *
     * @param {string} target
     * @param {string} event
     */
    const addEvent = (target, event) => {
        const events = getEventsByTarget(target, currentDate());
        if (
            events.indexOf(event) === -1 &&
            window.location.hostname !== "localhost"
        ) {
            addEventToLocalStorage(target, event);
            const dbRef = ref(getDatabase());
            update(dbRef, {
                [getCurrentUrlTargetEvent(target, event)]: increment(1),
            });
        }
    };

    return {
        addEvent,
    };
};

export default useNavigationStatics;
