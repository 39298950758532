import React from "react";
import products from "../../config/products";
import { Grid } from "@mui/material";
import background_gradient from "../../../media/resources/background_gradient.webp";

const GiftCard = ({ product }) => {
    const hasImage = () =>
        !!products[product].contents.media_gift[localStorage.getItem("fnl")];
    const hasText = () =>
        !!products[product].contents.text_gift[localStorage.getItem("fnl")];

    return products[product] && (hasImage || hasText) ? (
        <Grid
            container
            direction="row"
            sx={{
                my: 1,
                backgroundImage: "url(" + background_gradient + ")",
                p: 1,
                borderRadius: "0.3rem",
            }}
        >
            {hasImage ? (
                <Grid item xs={hasText ? 4 : 12}>
                    {
                        products[product].contents.media_gift[
                            localStorage.getItem("fnl")
                        ]
                    }
                </Grid>
            ) : (
                <></>
            )}

            {hasText ? (
                <Grid item xs={hasImage ? 8 : 12} sx={{ color: "#fff" }}>
                    {
                        products[product].contents.text_gift[
                            localStorage.getItem("fnl")
                        ]
                    }
                </Grid>
            ) : (
                <></>
            )}
        </Grid>
    ) : (
        <></>
    );
};

export default GiftCard;
