import React from "react";
import useValueByBreakpoints from "../../../helpers/hooks/UseValueByBreakpoints";
import envio from "../../../../media/product/eco_wash/images/envio.webp";
import { Box } from "@mui/material";

const Envio = () => {
    const price_image_width = useValueByBreakpoints({
        xs: "96%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const img_styles = {
        width: price_image_width,
        marginTop: "10px",
    };

    return (
        <Box>
            <img src={envio} alt="kit-ecologico" style={img_styles} />
        </Box>
    );
};

export default Envio;
