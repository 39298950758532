import React from "react";
/*import { WhatsApp } from "@mui/icons-material";*/
import { ButtonBase } from "@mui/material";
import products from "../../config/products";
import useNavigationStatics from "../hooks/useNavigationStatics";
import write_us from "../../../media/write_us.webp";

const WhatsappButton = ({ product }) => {
    const { addEvent } = useNavigationStatics();
    return (
        <ButtonBase
            focusRipple
            sx={{
                /*border: "2px solid #01e675",*/
                justifyContent: "flex-start",
                alignItems: "stretch",
                /*backgroundColor: "#01e675",*/
                /*borderRadius: "50%",*/
                borderRadius: "1rem",
                p: 0,
                m: 1,
            }}
            onClick={() => {
                addEvent(
                    products[product].route_name +
                        "_" +
                        localStorage.getItem("fnl"),
                    "to_whatsapp"
                );
                window.open(
                    "https://wa.me/57" +
                        products[product].whatsapp_number +
                        "?text=" +
                        products[product].contents.whatsapp_message,
                    "_blank"
                );
            }}
        >
            {/*<WhatsApp
                sx={{
                    color: "#FFF",
                    fontSize: "3rem",
                }}
            />*/}
            <img src={write_us} alt="Escríbenos" width="300px" />
        </ButtonBase>
    );
};

export default WhatsappButton;
