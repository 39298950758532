import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import footer_01 from "../../../../media/product/kit_renovacion/titulo_15.webp";
import footer_02 from "../../../../media/product/kit_renovacion/titulo_16.webp";
import fondo from "../../../../media/product/kit_renovacion/fondo_2.webp";
import WhatsappButton from "../../../helpers/components/WhatsappButton";

const Footer = ({ product }) => {
    const copiStyle = {
        maxWidth: "92%", // Ajusta el tamaño máximo del copo a un 50% del contenedor
        height: "auto",
        zIndex: 1,
        marginTop: 5,
        color: "#FFFFFF",
        fontWeight: 600,
        fontSize: 20,
    };
    const year = new Date().getFullYear();
    return (
        <Box
            sx={{
                backgroundImage: `url(${fondo})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                position: "relative",
                py: 2,
            }}
        >
            <Grid>
                <Box sx={{ pt: 2, px: { xs: 2, sm: 4 } }}>
                    <img src={footer_01} alt="kit" width="100%" />
                </Box>
                <Box sx={{ px: { xs: 2, sm: 4 } }}>
                    <img src={footer_02} alt="kit" width="100%" />
                </Box>
                <Box>
                    <WhatsappButton product={product} />
                </Box>
                <Box style={copiStyle}>
                    <Typography variant="span">
                        &copy; {year} - By {""}
                    </Typography>
                    <Typography variant="span">BOOSTEC</Typography>
                </Box>
            </Grid>
        </Box>
    );
};

export default Footer;
