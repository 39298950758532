import React from "react";
import { Box, Fab } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CloseDrawerButton = ({ onClick }) => {
    return (
        <Box
            position="sticky"
            sx={{ top: 0, right: 0, pr: 4, pt: 4, textAlign: "right" }}
        >
            <Fab size="small" aria-label="Cerrar" onClick={onClick}>
                <CloseIcon />
            </Fab>
        </Box>
    );
};

export default CloseDrawerButton;
