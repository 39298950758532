import { createTheme, responsiveFontSizes } from "@mui/material";
import getTheme from "../../config/theme";

const useFunnelTheme = () => {
    const getFunnelTheme = (product) => {
        let theme;
        switch (product) {
            case "eco_wash":
                theme = createTheme(getTheme("green_light"));
                break;
            case "kit_renovacion":
                theme = createTheme(getTheme("kit_renovacion"));
                break;
            case "eco_clear":
                theme = createTheme(getTheme("eco_clear"));
                break;
            case "ultra_renovacion":
                theme = createTheme(getTheme("kit_renovacion"));
                break;
            default:
                theme = createTheme(getTheme("orange_dark"));
                break;
        }

        theme = responsiveFontSizes(theme);
        return theme;
    };

    return {
        getFunnelTheme,
    };
};

export default useFunnelTheme;