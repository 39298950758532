import Content from "../../products/components/ultra_renovacion/Content";
import Presentation from "../../products/components/ultra_renovacion/Presentation";
import Promotion from "../../products/components/ultra_renovacion/Promotion";

const ultra_renovacion = {
    code: "4",
    name: "MEGA KIT ULTRA RENOVACIÓN",
    route_name: "ultra_renovacion",
    price: 79900,
    apis: {
        funnel_5: {
            // url: "http://localhost:8000/api/new-order",
            url: "https://boostec-sp-auto-zy9m3.ondigitalocean.app/api/new-order",
        },
    },
    contents: {
        presentation: { funnel_5: <Presentation /> },
        contents: { funnel_5: <Content /> },
        promotion: { funnel_5: <Promotion /> },
        presentations: [{ price: "$79.900", description: "" }],
        default_presentation: 0,
        whatsapp_message:
            "Hola, estoy interesado(a) en el *Mega kit Ultra Renovacion*",
        route_image_xs:
            window.location.origin + "/statics/kit_renovacion_x3_xs.webp",
        card_content: {
            title: "MEGA KIT ULTRA RENOVACION",
            description:
                "Limpieza, maximo brillo, mejor vision al conducir y partes negras restauradas",
            price: "$79.900",
            path: "/product/5/ultra_renovacion",
        },
    },
    related: [],
    webhook:
        "https://hooks.slack.com/services/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",
    channel: "#ventas-kit-ultra-renovacion",
    whatsapp_number: "3104384438",
    pixel: {
        funnel_5: "1651540042041038",
    },
};

export default ultra_renovacion;
