import React, { useState } from 'react'
import desengrasante from "../../../../media/product/eco_wash/images/desengrasante.webp";
import useValueByBreakpoints from '../../../helpers/hooks/UseValueByBreakpoints';
import { Box} from '@mui/material';

const Adicion = () => {
  
    const price_image_width = useValueByBreakpoints({
        xs: "96%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const img_styles = {
        width: price_image_width,
        marginTop: "10px",
    };
  return (
    <Box>
        <img src={desengrasante} alt="cristales" style={img_styles} />
    </Box>
  )
}

export default Adicion