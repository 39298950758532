import { Box } from "@mui/material";
import React from "react";

import kit from "../../../../media/product/eco_clear/kit.webp";
import producto from "../../../../media/product/eco_clear/producto_2.webp";
import obsequio from "../../../../media/product/eco_clear/obsequio.webp";
import useValueByBreakpoints from "../../../helpers/hooks/UseValueByBreakpoints";

const Content = () => {
    const price_image_width = useValueByBreakpoints({
        xs: "96%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const im_styles = {
        width: price_image_width,
    };
    return (
        <>
            <Box mt={3}>
                <img src={kit} alt="kit" style={im_styles} />
            </Box>
            <Box>
                <img src={producto} alt="producto" style={im_styles} />
            </Box>
            <Box mt={3} id="super_promo">
                <img src={obsequio} alt="obsequio" style={im_styles} />
            </Box>
        </>
    );
};

export default Content;
