import { Box, Grid } from "@mui/material";
import React from "react";
import fondo from "../../../../media/product/eco_clear/fondo.webp";
import titulo from "../../../../media/product/eco_clear/titulo1.webp";
import antes_despues from "../../../../media/product/eco_clear/antes_despues.webp";
import producto from "../../../../media/product/eco_clear/producto.webp";
import BtnRealizar from "../../../helpers/components/BtnRealizar";

const Presentation = () => {
    const kitStyle = {
        maxWidth: "80%", // Ajusta el tamaño máximo del copo a un 50% del contenedor
        height: "auto",
        zIndex: 1,
    };
    const styleProducto = {
        maxWidth: "110%", // Ajusta el tamaño máximo del copo a un 50% del contenedor
        height: "auto",
        zIndex: 1,
    };
    const styleTitle = {
        maxWidth: "95%", // Ajusta el tamaño máximo del copo a un 50% del contenedor
        height: "auto",
        zIndex: 1,
        marginTop: 8,
    };
    return (
        <Box
            sx={{
                backgroundImage: `url(${fondo})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                position: "relative",
            }}
        >
            <img src={titulo} alt="titulo1" style={styleTitle} />
            <Grid
                container
                style={{
                    alignItems: "center",
                    marginLeft: "-20px",
                    paddingBottom: "25px",
                }}
            >
                <Grid item xs={6}>
                    <img
                        src={antes_despues}
                        alt="antes_despues"
                        style={kitStyle}
                    />
                </Grid>
                <Grid item xs={6}>
                    <img src={producto} alt="producto" style={styleProducto} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Presentation;
