import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Referidos from "../pages/referidos/Referidos";
import FunnelFilter from "../pages/funnels/FunnelFilter";
import routes from "../config/routes";
import NotFound from "../helpers/components/NotFound";
import Home from "../pages/home/Home";

const Routes = () => {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Home />,
        },
        {
            path: "/product/:funnel/:product",
            element: <FunnelFilter />,
        },
        {
            path: routes.referidos.path,
            element: <Referidos />,
        },
        {
            path: "*",
            element: <NotFound />,
        },
    ]);

    return <RouterProvider router={router} />;
};

export default Routes;
