import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Chip,
    Paper,
    Typography,
} from "@mui/material";
import {
    ArrowForward as ArrowForwardIcon,
    LocalShipping as LocalShippingIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import products from "../../../../config/products";

export default function ProductCard({ product }) {
    const getData = (field) => {
        return products[product].contents.card_content[field];
    };

    return (
        <Card sx={{ display: "flex" }}>
            <Box sx={{ width: { xs: "150px", sm: "270px", lg: "300px" } }}>
                <Paper
                    elevation={6}
                    sx={{
                        position: "absolute",
                        transform: "translateY(20px) translateX(-20px)",
                        borderRadius: "0.5rem",
                    }}
                >
                    <CardMedia
                        component="img"
                        sx={{
                            width: { xs: "90px", sm: "170px", lg: "130px" },
                            borderRadius: "0.5rem",
                        }}
                        image={getData("media")}
                        alt="Live from space album cover"
                    />
                </Paper>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", pl: 2 }}>
                <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography variant="h5">{getData("title")}</Typography>
                    <Typography>{getData("description")}</Typography>
                    <Box sx={{ mt: 2 }}>
                        <Chip
                            label={"Precio " + getData("price")}
                            color="success"
                            variant="outlined"
                        />
                        <Typography variant="caption" display="block">
                            <LocalShippingIcon
                                sx={{ fontSize: "1.5rem", mb: -0.6 }}
                            />{" "}
                            Envío gratis
                        </Typography>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                        <Link to={getData("path")}>
                            <Button variant="contained">
                                Más información{" "}
                                <ArrowForwardIcon sx={{ ml: 1 }} />
                            </Button>
                        </Link>
                    </Box>
                </CardContent>
            </Box>
        </Card>
    );
}
