import React from "react";
import kit2 from "../../../../media/product/eco_wash/images/kit2.webp";
import { Box } from "@mui/material";
import useValueByBreakpoints from "../../../helpers/hooks/UseValueByBreakpoints";

const TresUno = () => {
    const price_image_width = useValueByBreakpoints({
        xs: "96%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const img_styles = {
        width: price_image_width,
        marginTop: "10px",
    };

    return (
        <Box sx={{ pt: 4 }}>
            <img src={kit2} alt="kit2" style={img_styles} />
        </Box>
    );
};

export default TresUno;
