import React from "react";
import {
    Box,
    Container,
    Unstable_Grid2 as Grid,
    ThemeProvider,
} from "@mui/material";
import products from "../../../config/products";
import PurchaseForm from "../../../helpers/components/PurchaseForm";
import HeaderForm from "../../../helpers/components/HeaderForm";
import Footer from "../../../products/components/eco_wash/Footer";
import useFunnelTheme from "../../../helpers/hooks/useFunnelTheme";
import BtnRealizar from "../../../helpers/components/BtnRealizar";
import BtnKit from "../../../helpers/components/BtnKit";
import MessagePurchaseCompleted from "../../../helpers/components/MessagePurchaseCompleted";
import { useState } from "react";
import Garantia from "../../../helpers/components/Garantia";
import Comentarios from "../../../helpers/components/Comentarios";

const Funnel3 = ({ product }) => {
    const { getFunnelTheme } = useFunnelTheme();
    const [showMessagePurchaseCompleted, setShowMessagePurchaseCompleted] =
        useState(false);

    const gradientStyle = {
        background: "linear-gradient(to bottom, #F4F4F4, #c4e2a6, #F4F4F4)",
    };
    const gradientStyle2 = {
        background: "linear-gradient(to bottom, #F4F4F4, #c4e2a6)",
    };
    const gradientStyle3 = {
        background: "linear-gradient(to top, #F4F4F4, #c4e2a6)",
    };

    return (
        <ThemeProvider theme={getFunnelTheme(product)}>
            <Grid
                sx={{ backgroundColor: "#F4F4F4" }}
                container
                justifyContent="center"
            >
                <Grid item xs={12} sm={8} md={3} sx={{ textAlign: "center" }}>
                    <Box>
                        <Box sx={{ p: 1 }}>
                            {
                                products[product].contents.title[
                                    localStorage.getItem("fnl")
                                ]
                            }
                        </Box>
                        <Box>
                            {
                                products[product].contents.superkit[
                                    localStorage.getItem("fnl")
                                ]
                            }
                        </Box>
                        <Box>
                            {
                                products[product].contents.presentation[
                                    localStorage.getItem("fnl")
                                ]
                            }
                        </Box>
                        <BtnKit product={product} />
                    </Box>
                    <Box sx={{ ...gradientStyle }}>
                        <Box>
                            {
                                products[product].contents.question[
                                    localStorage.getItem("fnl")
                                ]
                            }
                        </Box>
                        <Box>
                            {
                                products[product].contents.arguments[
                                    localStorage.getItem("fnl")
                                ]
                            }
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            {
                                products[product].contents.include[
                                    localStorage.getItem("fnl")
                                ]
                            }
                        </Box>
                    </Box>
                    <Box sx={{ p: 0, textAlign: "center" }}>
                        <Box>
                            {
                                products[product].contents.include2[
                                    localStorage.getItem("fnl")
                                ]
                            }
                        </Box>
                    </Box>
                    <Box>
                        <Box>
                            {
                                products[product].contents.video[
                                    localStorage.getItem("fnl")
                                ]
                            }
                        </Box>
                        <BtnRealizar product={product} />
                    </Box>
                    {/* <Box sx={{ ...gradientStyle3 }}>
                        {
                            products[product].contents.tresuno[
                                localStorage.getItem("fnl")
                            ]
                        }
                    </Box> */}
                    <Box>
                        {
                            products[product].contents.kitecologico[
                                localStorage.getItem("fnl")
                            ]
                        }
                    </Box>
                    <Box>
                        {
                            products[product].contents.price[
                                localStorage.getItem("fnl")
                            ]
                        }
                    </Box>
                    <Box>
                        {
                            products[product].contents.envio[
                                localStorage.getItem("fnl")
                            ]
                        }
                    </Box>
                    <Container>
                        <Box
                            sx={{
                                border: 3,
                                borderColor: "primary.main",
                                borderRadius: 5,
                                p: 0.5,
                                boxShadow: 8,
                            }}
                        >
                            <HeaderForm product={product} />
                            <PurchaseForm
                                product={product}
                                onPurchase={() =>
                                    setShowMessagePurchaseCompleted(true)
                                }
                            />
                            <MessagePurchaseCompleted
                                open={showMessagePurchaseCompleted}
                                onClose={() =>
                                    setShowMessagePurchaseCompleted(false)
                                }
                                product={product}
                            />
                        </Box>
                    </Container>
                    <Garantia product={product}/>
                    <Comentarios product={product}/>
                    <BtnRealizar product={product}/>
                    <Footer product={product} />
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default Funnel3;
