import React from "react";

import titulo2 from "../../../../media/product/eco_clear/titulo2.webp";
import titulo3 from "../../../../media/product/eco_clear/titulo3.webp";
import { Box } from "@mui/material";

const Informacion = () => {
    const styleTitle = {
        maxWidth: "95%", // Ajusta el tamaño máximo del copo a un 50% del contenedor
        height: "auto",
        zIndex: 1,
        marginTop: 15,
    };

    return (
        <Box>
            <img src={titulo2} alt="titulo2" style={styleTitle} />
            <Box mt={3}>
                <img
                    src="https://ecoclear.com.co/cdn/shop/files/Sin_titulo_600_x_600_px_3.gif?v=1687984221&width=750"
                    type="imgane gif"
                    width="100%"
                />
            </Box>
            <img src={titulo3} alt="titulo3" style={styleTitle} />
        </Box>
    );
};

export default Informacion;
