import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import titulo1 from "../../../../media/product/eco_wash/images/titulo1.webp";
import useValueByBreakpoints from "../../../helpers/hooks/UseValueByBreakpoints";

const Video = () => {
    const price_image_width = useValueByBreakpoints({
        xs: "96%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const im_styles = {
        width: price_image_width,
    };
    return (
        <Container sx={{ px: 0, mt: 3 }}>
            <Box>
                <img src={titulo1} alt="superKit" style={im_styles} />
            </Box>
           
        </Container>
    );
};

export default Video;
