/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
    Backdrop,
    Box,
    Button,
    Grid,
    SwipeableDrawer,
    Typography,
} from "@mui/material";
import anime from "animejs";
import PurchaseForm from "./PurchaseForm";
import CloseDrawerButton from "./CloseDrawerButton";
import useValueByBreakpoints from "../hooks/UseValueByBreakpoints";
import ContentPurchaseCompleted from "./ContentPurchaseCompleted";
import useNavigationStatics from "../hooks/useNavigationStatics";
import products from "../../config/products";
import { getDatabase, ref, update } from "firebase/database";
import GiftCard from "./GiftCard";
import { LocalShipping } from "@mui/icons-material";

const BuyNowButton = ({ product }) => {
    const { addEvent } = useNavigationStatics();
    const [showDrawer, setShowDrawer] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [stringData, setStringData] = useState("");
    const anchor = useValueByBreakpoints({ xs: "right", sm: "right" });
    const purchaseCompleted = useRef(false);

    useEffect(() => {
        anime({
            targets: "#buy_now_button",
            scale: [0.95, 1.05],
            direction: "alternate",
            easing: "easeOutBack",
            loop: true,
        });
    }, []);

    const currentDate = () => {
        const date = new Date();
        return (
            date.getDate() +
            "_" +
            (date.getMonth() + 1) +
            "_" +
            date.getFullYear() +
            "_" +
            date.getHours() +
            "_" +
            date.getMinutes() +
            "_" +
            date.getSeconds()
        );
    };

    useEffect(() => {
        if (
            !showDrawer &&
            stringData &&
            !purchaseCompleted.current &&
            window.location.hostname !== "localhost"
        ) {
            const dbRef = ref(getDatabase());
            update(dbRef, {
                ["purchase_attempts/" + currentDate()]: stringData,
            });
        }
    }, [showDrawer]);

    return (
        <React.Fragment>
            <Button
                id="buy_now_button"
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                onClick={() => {
                    addEvent(
                        products[product].route_name +
                            "_" +
                            localStorage.getItem("fnl"),
                        "to_buy"
                    );
                    setShowDrawer(true);
                }}
            >
                COMPRAR Y PAGAR EN CASA{" "}
                <LocalShipping fontSize="small" sx={{ ml: 1 }} />
            </Button>
            <SwipeableDrawer
                open={showDrawer}
                anchor={anchor}
                onClose={() => setShowDrawer(false)}
                onOpen={() => setShowDrawer(true)}
            >
                <CloseDrawerButton onClick={() => setShowDrawer(false)} />
                <Box
                    sx={{
                        px: 2,
                        mt: -4,
                        width: { xs: "auto", sm: 532, md: 632 },
                    }}
                >
                    <Typography variant="h3">Realiza tu pedido</Typography>
                    <GiftCard product={product} />
                    <Box sx={{ my: 2 }}>
                        <PurchaseForm
                            product={product}
                            onPurchase={() => {
                                purchaseCompleted.current = true;
                                setShowDrawer(false);
                                setShowSuccessMessage(true);
                            }}
                            onChangeData={(data) => setStringData(data)}
                        />
                    </Box>
                </Box>
            </SwipeableDrawer>

            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={showSuccessMessage}
                onClose={() => setShowSuccessMessage(false)}
            >
                <Box textAlign="center" p={2}>
                    <ContentPurchaseCompleted product={product} />
                    <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        fullWidth
                        sx={{ mt: 2 }}
                        onClick={() => setShowSuccessMessage(false)}
                    >
                        Cerrar
                    </Button>
                </Box>
            </Backdrop>
        </React.Fragment>
    );
};

export default BuyNowButton;
