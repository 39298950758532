import React from "react";
import video_partes_negras from "../../../../../media/product/kit_renovacion/videos/partes-negras.mp4";
import partes_negras from "../../../../../media/product/kit_renovacion/renovador_partes_negras.webp";
import useValueByBreakpoints from "../../../../helpers/hooks/UseValueByBreakpoints";
const PartesNegras = () => {
    const price_image_width = useValueByBreakpoints({
        xs: "100%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const img_styles = {
        width: price_image_width,
    };
    return (
        <>
            <img src={partes_negras} style={img_styles} />
            <video width="100%" autoPlay muted loop>
                <source src={video_partes_negras} type="video/mp4" />
            </video>
        </>
    );
};

export default PartesNegras;
