import React from "react";

import { Typography } from "@mui/material";
import transportadoras from "../../../media/resources/transportadoras.webp";

const LogisticsOperators = () => {
    return (
        <>
            <Typography variant="h3" sx={{ mt: 4, mb: 2 }}>
                Envíos
            </Typography>
            <Typography sx={{ mb: 4 }}>
                Los mejores operadores logísticos llevarán tu pedido hasta la
                puerta de tu casa y en el menor tiempo posible.
            </Typography>
            <img
                src={transportadoras}
                alt="Transportadoras"
                style={{
                    width: "100%",
                    marginBottom: "2rem",
                }}
            />
        </>
    );
};

export default LogisticsOperators;
