import React, { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Card,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";

const ItemTestimony = ({ photo, name, message, date }) => {
    const theme = useTheme();
    return (
        <>
            <ListItem
                alignItems="flex-start"
                component={Paper}
                sx={{
                    my: 2,
                    backgroundColor:
                        theme.palette.type === "dark"
                            ? "rgba(41,41,41,0.6)"
                            : "rgba(255,255,255,0.7)",
                    backdropFilter: "blur(5px)",
                }}
                elevation={5}
            >
                <ListItemAvatar>
                    <Avatar alt={name} src={photo} />
                </ListItemAvatar>
                <ListItemText
                    primary={name}
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                {date}
                            </Typography>
                            {" — "}
                            {message}
                        </React.Fragment>
                    }
                />
            </ListItem>
        </>
    );
};
const Testimonials = ({
    data,
    //url = "http://localhost:3000/product/1/kit_renovacion",
    url = "https://e908-67-73-232-65.ngrok-free.app/product/1/kit_renovacion",
}) => {
    const theme = useTheme();
    const loadComments = () => {
        //console.log(window.FB.XFBML);
        window.FB.XFBML.parse();
    };

    useEffect(() => {
        setTimeout(() => {
            loadComments();
        }, 5000);
    }, []);

    return (
        <>
            <Typography variant="h3" sx={{ mb: 2 }}>
                Esto es lo que dicen nuestros clientes
            </Typography>
            <Box
                sx={{
                    width: "200px",
                    height: "200px",
                    backgroundColor: "#f26806",
                    marginBottom: "-200px",
                    borderRadius: "2rem",
                    position: "absolute",
                    right: 0,
                    transform:
                        "translateX(70%) translateY(140px) perspective(180px) rotate3d(1, 2, 1, 45deg)",
                }}
            ></Box>
            <div
                className="fb-comments"
                data-href={url}
                data-width="100%"
                data-numposts="5"
                //data-colorscheme="dark"
                style={{
                    backgroundColor:
                        theme.palette.type !== "dark"
                            ? "rgba(41,41,41,0.6)"
                            : "rgba(255,255,255,0.7)",
                    backdropFilter: "blur(5px)",
                }}
            ></div>
            <List /*sx={{ bgcolor: "background.paper" }}*/>
                {/*data.map((item, i) => (
                    <ItemTestimony
                        key={i}
                        photo={item.photo}
                        name={item.name}
                        message={item.message}
                        date={item.date}
                    />
                ))*/}
            </List>
        </>
    );
};

export default Testimonials;
