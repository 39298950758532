import React from "react";
import Presentation from "../../products/components/eco_clear/Presentation";
import Informacion from "../../products/components/eco_clear/Informacion";
import Content from "../../products/components/eco_clear/Content";
import Actions from "../../products/components/eco_clear/Actions";
import Contraentrega from "../../products/components/eco_clear/Contraentrega";
import Footer from "../../products/components/eco_clear/Footer";

const eco_clear = {
    code: "3",
    name: "KIT ECO CLEAR",
    route_name: "eco_clear",
    price: 69900,
    apis: {
        funnel_4: {
            // url: "http://localhost:8000/api/new-order",
            url: "https://boostec-sp-auto-zy9m3.ondigitalocean.app/api/new-order",
        },
    },
    contents: {
        presentation: { funnel_4: <Presentation /> },
        informacion: { funnel_4: <Informacion /> },
        actions: { funnel_4: <Actions /> },
        content: { funnel_4: <Content /> },
        contraentrega: { funnel_4: <Contraentrega /> },
        presentations: [{ price: "$69.900", description: "MÁS PAÑO" }],
        default_presentation: 0,
        whatsapp_message: "Hola, estoy interesado(a) en el *kit Eco Clear*",
        card_content: {
            title: "KIT SUPER ECO CLEAR",
            description:
                "Remueve manchas, elimina grasa y jabón, quita moho y hongos, desmancha baldosas",
            price: "$69.900",
            path: "/product/4/eco_clear",
        },
    },
    related: [],
    webhook:
        "https://hooks.slack.com/services/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",
    channel: "#ventas-kit-eco-clear",
    whatsapp_number: "3104384438",
    pixel: {
        funnel_4: "150504843957180",
    },
};

export default eco_clear;
