import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import img_garantia from "../../../media/resources/garantia.webp";
import img_comercio from "../../../media/resources/comercio.webp";
import img_sellos from "../../../media/resources/sellos.webp";

const Warranty = () => {
    return (
        <>
            <Typography variant="h3" sx={{ mb: 2 }}>
                GARANTÍA
            </Typography>
            <Stack direction="row" my={2}>
                <Box>
                    <img
                        src={img_garantia}
                        alt="Garantía"
                        style={{
                            width: "100%",
                        }}
                    />
                </Box>
                <Box>
                    <img
                        src={img_comercio}
                        alt="Garantía"
                        style={{
                            width: "100%",
                        }}
                    />
                </Box>
            </Stack>
            <Typography>
                Garantía de satisfacción del 100%. LUBRISTONE 35 años en el
                mercado, dedicada a la producción y comercialización de
                productos para el cuidado y estética automotriz. Con presencia
                en todo el territorio nacional colombiano y con presencia
                internacional en 10 países.
            </Typography>

            <Box mt={2}>
                <img
                    src={img_sellos}
                    alt="Garantía"
                    style={{
                        width: "100%",
                    }}
                />
            </Box>
        </>
    );
};

export default Warranty;
