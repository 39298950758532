import { Box, Container } from "@mui/material";
import React from "react";
import aliados from "../../../../media/product/kit_renovacion/aliados.webp";
import useValueByBreakpoints from "../../../helpers/hooks/UseValueByBreakpoints";

const ContraEntrega = () => {
    const price_image_width = useValueByBreakpoints({
        xs: "100%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const img_styles = {
        width: price_image_width,
    };
    return (
        <Container>
            <Box id="super_promo">
                <img src={aliados} style={img_styles} alt="" />
            </Box>
        </Container>
    );
};

export default ContraEntrega;
