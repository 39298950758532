import React from "react";
import useValueByBreakpoints from "../../../helpers/hooks/UseValueByBreakpoints";
import { Box } from "@mui/material";

import contenido from "../../../../media/product/ultra_renovacion/titulo_5.png";
import shampoo from "../../../../media/product/ultra_renovacion/producto1.png";
import tituloCera from "../../../../media/product/ultra_renovacion/titulo_6.png";
import cera from "../../../../media/product/ultra_renovacion/producto2.png";
import tituloEmpañante from "../../../../media/product/ultra_renovacion/titulo_7.png";
import antiEmpañante from "../../../../media/product/ultra_renovacion/producto3.png";
import partesNegras from "../../../../media/product/kit_renovacion/renovador_partes_negras.webp";
import video_partes_negras from "../../../../media/product/kit_renovacion/videos/partes-negras.mp4";
import video_antiempañante from "../../../../media/product/ultra_renovacion/videos/antiempañante_1.mp4";
import video_cera from "../../../../media/product/ultra_renovacion/videos/cera_1.mp4";
import video_shampoo from "../../../../media/product/ultra_renovacion/videos/shampoo_1.mp4";

const Content = () => {
    const price_image_width = useValueByBreakpoints({
        xs: "96%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const img_styles = {
        width: price_image_width,
        marginTop: "10px",
    };
    return (
        <>
            <Box>
                <img src={contenido} alt="contenito title" style={img_styles} />
            </Box>
            <Box>
                <img src={shampoo} alt="producto shampoo" style={img_styles} />
            </Box>
            <Box>
                <video width="100%" autoPlay muted loop>
                    <source src={video_shampoo} type="video/mp4" />
                </video>
            </Box>
            <Box>
                <img
                    src={tituloCera}
                    alt="titulo producto cera"
                    style={img_styles}
                />
            </Box>
            <Box>
                <img src={cera} alt="producto cera" style={img_styles} />
            </Box>
            <Box>
                <video width="100%" autoPlay muted loop>
                    <source src={video_cera} type="video/mp4" />
                </video>
            </Box>
            <Box>
                <img
                    src={tituloEmpañante}
                    alt="titulo producto antiempañante"
                    style={img_styles}
                />
            </Box>
            <Box>
                <img
                    src={antiEmpañante}
                    alt="producto antiempañante"
                    style={img_styles}
                />
            </Box>
            <Box>
                <video width="100%" autoPlay muted loop>
                    <source src={video_antiempañante} type="video/mp4" />
                </video>
            </Box>
            <Box>
                <img
                    src={partesNegras}
                    alt="producto partes negras"
                    style={img_styles}
                />
            </Box>
            <Box>
                <video width="100%" autoPlay muted loop>
                    <source src={video_partes_negras} type="video/mp4" />
                </video>
            </Box>
        </>
    );
};

export default Content;
