import React from "react";
import useValueByBreakpoints from "../hooks/UseValueByBreakpoints";
import { Box } from "@mui/material";
import headerKit from "../../../media/product/kit_renovacion/tituloFormKit.webp";
import headerEco from "../../../media/product/eco_wash/images/tituloFormEco.webp";

const HeaderForm = ({ product }) => {
    const price_image_width = useValueByBreakpoints({
        xs: "96%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });
    const img_styles = {
        width: price_image_width,
        marginTop: "10px",
    };

    const headerForm = () => {
        switch (product) {
            case "eco_wash":
                return headerEco;
            case "kit_renovacion":
                return headerKit;
            case "ultra_renovacion":
                return headerKit;
            case "eco_clear":
                return headerKit;
        }
    };
    return (
        <Box sx={{ my: 1 }}>
            <img src={headerForm()} alt="completar" style={img_styles} />
        </Box>
    );
};

export default HeaderForm;
