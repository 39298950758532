import Arguments from "../../products/components/eco_wash/Arguments";
import BtnKit from "../../helpers/components/BtnKit";
import BtnRealizar from "../../helpers/components/BtnRealizar";
import Include from "../../products/components/eco_wash/Include";
import Include_2 from "../../products/components/eco_wash/Include_2";
import KitEcologico from "../../products/components/eco_wash/KitEcologico";
import Presentation from "../../products/components/eco_wash/Presentation";
import Question from "../../products/components/eco_wash/Question";
import SuperKIt from "../../products/components/eco_wash/SuperKIt";
import Title from "../../products/components/eco_wash/Title";
import TresUno from "../../products/components/eco_wash/TresUno";
import Video from "../../products/components/eco_wash/Video";
import Envio from "../../products/components/eco_wash/Envio";
import Footer from "../../products/components/eco_wash/Footer";
import media_kit_eco_wash from "../../../media/product/images/kit_eco_wash.webp";
import Price from "../../helpers/components/Price";

const eco_wash = {
    codes: ["2", "12"],
    name: "KIT ECO WASH",
    route_name: "eco_wash",
    price: 69900,
    apis: {
        funnel_3: {
            // url: "http://localhost:8000/api/new-order",
            url: "https://boostec-sp-auto-zy9m3.ondigitalocean.app/api/new-order",
        },
    },
    contents: {
        title: { funnel_3: <Title /> },
        superkit: { funnel_3: <SuperKIt /> },
        presentation: { funnel_3: <Presentation /> },
        btnkit: { funnel_3: <BtnKit /> },
        question: { funnel_3: <Question /> },
        arguments: { funnel_3: <Arguments /> },
        include: { funnel_3: <Include /> },
        include2: { funnel_3: <Include_2 /> },
        video: { funnel_3: <Video /> },
        btnrealizar: { funnel_3: <BtnRealizar /> },
        tresuno: { funnel_3: <TresUno /> },
        kitecologico: { funnel_3: <KitEcologico /> },
        price: { funnel_3: <Price product="eco_wash" /> },
        envio: { funnel_3: <Envio /> },
        footer: { funnel_3: <Footer /> },
        presentations: [
            {
                price: "$69.900",
                description: "x3 TARROS + 1 PAÑO DE MICROFIBRA",
            },
            {
                price: "$89.900",
                description: "x4 TARROS + 1 PAÑO DE MICROFIBRA",
            },
        ],
        default_presentation: 0,
        whatsapp_message: "Hola, estoy interesado(a) en el *kit Eco Wash*",
        route_image_xs: window.location.origin + "/statics/superKit.webp",
        card_content: {
            title: "KIT SUPER ECO WASH",
            description:
                "Formula 100% ecologica que lava, brilla, policha, protege, mantiene limpio tu carro o moto hasta por 55 días.",
            price: "$69.900",
            path: "/product/3/eco_wash",
            media: media_kit_eco_wash,
        },
    },
    related: [],
    webhook:
        "https://hooks.slack.com/services/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",
    channel: "#ventas-kit-eco-wash",
    whatsapp_number: "3104384438",
    pixel: {
        funnel_3: "828960938815634",
    },
    tiktok_pixel: {
        funnel_3: "CKG3023C77U2JMMIVH00",
    },
};

export default eco_wash;
