import React from "react";
import useValueByBreakpoints from "../../../helpers/hooks/UseValueByBreakpoints";

import garantizado from "../../../../media/product/eco_clear/titulo5.webp";
import acciones from "../../../../media/product/eco_clear/acciones.webp";
import { Box } from "@mui/material";

const Actions = () => {
    const price_image_width = useValueByBreakpoints({
        xs: "96%",
        sm: "80%",
        md: "60%",
        lg: "60%",
        xl: "60%",
    });

    const im_styles = {
        width: price_image_width,
    };
    return (
        <>
            <Box mt={2}>
                <img src={garantizado} alt="garantizado" style={im_styles} />
            </Box>
            <Box mt={3}>
                <img src={acciones} alt="acciones" style={im_styles} />
            </Box>
            <Box mt={3}>
                <img
                    src="https://ecoclear.com.co/cdn/shop/files/Sin_titulo_600_x_600_px_2.gif?v=1687983611&width=750"
                    type="imgane gif"
                    width="100%"
                />
            </Box>
        </>
    );
};

export default Actions;
