import React, { useEffect, useState } from 'react'
import useFunnelTheme from '../../../helpers/hooks/useFunnelTheme';
import useNavigationStatics from '../../../helpers/hooks/useNavigationStatics';
import products from "../../../config/products";
import { ThemeProvider } from '@emotion/react';
import { Box, Container, Grid } from '@mui/material';
import BtnRealizar from '../../../helpers/components/BtnRealizar';
import HeaderForm from '../../../helpers/components/HeaderForm';
import PurchaseForm from '../../../helpers/components/PurchaseForm';
import MessagePurchaseCompleted from '../../../helpers/components/MessagePurchaseCompleted';
import Garantia from '../../../helpers/components/Garantia';
import Comentarios from '../../../helpers/components/Comentarios';
import Footer from '../../../products/components/eco_clear/Footer';

const Funnel4 = ({ product }) => {
    const { addEvent } = useNavigationStatics();
    const { getFunnelTheme } = useFunnelTheme();
    const [showMessagePurchaseCompleted, setShowMessagePurchaseCompleted] =
        useState(false);

    useEffect(() => {
        addEvent(
            products[product].route_name + "_" + localStorage.getItem("fnl"),
            "start_page"
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const gradientStyle = {
        background: "linear-gradient(to bottom, #F4F4F4, #00b0fa, #F4F4F4)",

    };
    return (
        <ThemeProvider theme={getFunnelTheme(product)}>
            <Grid container
                justifyContent="center">
                <Grid item xs={12} sm={8} md={3} sx={{ textAlign: "center" }}>
                    <Box>
                        {
                            products[product].contents.presentation[
                            localStorage.getItem("fnl")
                            ]
                        }
                    </Box>
                    <Box sx={{ ...gradientStyle }}>
                        <Box mt={-3}>
                            <BtnRealizar product={product} />
                        </Box>
                        <Box >
                            {
                                products[product].contents.informacion[
                                localStorage.getItem("fnl")
                                ]
                            }
                        </Box>
                        <Box>
                            <BtnRealizar product={product} />
                        </Box>
                        <Box>
                            {
                                products[product].contents.actions[
                                localStorage.getItem("fnl")
                                ]
                            }
                        </Box>
                        <Box>
                            {
                                products[product].contents.content[
                                localStorage.getItem("fnl")
                                ]
                            }
                        </Box>
                    </Box>
                    <Box>
                        <Box>
                            {
                                products[product].contents.contraentrega[
                                localStorage.getItem("fnl")
                                ]
                            }
                        </Box>
                        <Container>
                            <Box sx={{ border: 3, borderColor: '#00B0FA', borderRadius: 5, p: 0.5, boxShadow: 8 }}>
                                <HeaderForm product={product} />
                                <PurchaseForm
                                    product={product}
                                    onPurchase={() =>
                                        setShowMessagePurchaseCompleted(true)
                                    }
                                />
                                <MessagePurchaseCompleted
                                    open={showMessagePurchaseCompleted}
                                    onClose={() =>
                                        setShowMessagePurchaseCompleted(false)
                                    }
                                    product={product}
                                />
                            </Box>
                        </Container>
                        <Garantia product={product} />
                        <Comentarios product={product} />
                        <BtnRealizar product={product} />
                        <Footer product={product}/>
                    </Box>
                </Grid>

            </Grid>
        </ThemeProvider>
    );
}

export default Funnel4